<template>
    <div class="overlay">

        <ShopStatus></ShopStatus>
    </div>
  </template>
  
  <script>
  import ShopStatus from '@/components/MenuComps/ShopStatus.vue';
  export default {
    name: "Demo",
    components: {
        ShopStatus
    },
    data() {
      return {

      };
    },
    mounted() {
        
    },
    beforeDestroy() {
      clearInterval(this.intervalId);
    },
    methods: {
      
    }
  };
  </script>
  
  <style lang="scss" scoped>
  
  </style>
  