import { baseUrl } from "@/config";
import axios from "axios";
import { Message, Alert } from 'element-ui';
import bus from "@/utils/bus.js";
import qs from "qs";
import { getUserCountryCode } from "@/utils/judgeUseGoogle.js";

const isMobile = window.innerWidth < 950;
const instance = axios.create({
    baseURL: baseUrl,
    timeout: 30000,
    methods: 'POST',
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'DeviceType': isMobile?'H5':'Web',
		'AppVersion': '1.7.10',
    },
    // 携带凭证
    withCredentials: false,
    // 返回数据类型
    responseType: 'json'
});


// 请求拦截器
instance.interceptors.request.use(function(config) {
    // 在发送请求之前做些什么
    config.headers.token = localStorage.getItem('token')
    config.headers.refreshToken = localStorage.getItem('refreshToken')

    return config;
}, function(error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});


// 添加响应拦截器
instance.interceptors.response.use(function(response) {
    // 2xx 范围内的状态码都会触发该函数。
    // 对响应数据做点什么
    if (response.data.code == 200 || response.data.success || response.data.client_token) {
        return response.data
    } else {

        // 商家不在运营时间
        if (response.data.code == "50030") {
            Alert('The shop is closed now, please select the delivery time after the shop opens.?', 'Warning', {
                confirmButtonText: 'Confirm',
                center: true,
                callback: (action) => {
                    console.log(action)
                }
            });
            Message.error('The shop is closed now, please select the delivery time after the shop opens.')
        }

        // 注册邮箱重复
        if (response.data.code == "50021") {
            Message.error('Email registered.')
        }

        // 超出派送范围
        if (response.data.code == "50022") {
            Message.error('Over_Delivery')
        }

        // 积分不足 提示
        if (response.data.code == "50023") {
            Message.error('Lack of Points')
        }

        // 手机号不存在 提示
        if (response.data.code == "50028") {
            Message.error('The phone does not exist')
        }

        // 手机号不存在 提示
        if (response.data.code == "50029") {
            Message.error('The email does not exist')
        }

        // 手机号不存在 提示
        if (response.data.code == "50032") {
            Message.error(response.data.msg)
        }

        // 优惠码不合规使用
        if (response.data.code == "50035") {
            Message.error(response.data.msg)
        }

        // 不派送某个邮编
        if (response.data.code == "50024") {
            Message.error('Merchants ban this postcode')
        }

        // 超出金额不允许添加进购物车
        if (response.data.code == "50034") {
            Message.error(response.data.msg)
        }

        if(response.data.code == 401) {
            Message.error('Login Expired.')
            setTimeout(() => {
                bus.$emit('logout');
                localStorage.removeItem('token');
                localStorage.removeItem('clientId');
                location.href = "/login";
            }, 1000);
        }

        return Promise.reject(response.data);
    }
}, function(error) {
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    const response = error.response
    if(response.data.code == 401) {
        Message.error('Login Expired.')
        setTimeout(() => {
            bus.$emit('logout');
            localStorage.removeItem('token');
            localStorage.removeItem('clientId');
            location.href = '/login'
        }, 1000);
    }
    return Promise.reject(error);
});


let countryCode = '';
getUserCountryCode().then((res) => {
    countryCode = res.countryCode;
})

// CountryCode 用来标记这个用户的地区  
// 英国 GBR
// 爱尔兰 IRL


export function postGateway(params) {
    let url = params.url;
    if (params.method && params.method.toLocaleLowerCase() == 'get') {
        url += '?' + qs.stringify(params.data)
    }
    return instance({
        method: params.method ? params.method : 'POST',
        url: url,
        data: params.data,
        headers: {
            ...params.headers,
            'CountryCode': countryCode,
			'AppVersion': '1.7.10',
        }
    })
}

export function postGatewayOther(params) {
    let url = params.url;
    return instance({
        method: params.method ? params.method : 'POST',
        url: url,
        data: params.data,
        headers: {
            ...params.headers,
            'CountryCode': countryCode
        }
    })
}

// let statusCNStrs = {
//     '400': '请求错误',
//     '401': '未授权，请登录',
//     '403': '拒绝访问',
//     '404': '请求地址出错',
//     '500': '服务器内部错误',
//     '501': '服务未实现',
//     '502': '网关错误',
//     '503': '服务不可用',
//     '504': '网关超时',
//     '505': 'HTTP版本不受支持',
//     'default': '',
// }
// let statusENStrs = {
//     '400': 'Request error',
//     '401': 'Unauthorized, please login',
//     '403': 'Access denied',
//     '404': 'Error requesting address',
//     '500': 'Server internal error',
//     '501': 'Service not implemented',
//     '502': 'Bad Gateway',
//     '503': 'Service Unavailable',
//     '504': 'Gateway timeout',
//     '505': 'The HTTP version is not supported',
//     'default': '',
// }