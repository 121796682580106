<template>
  <div>
    <div class="cate-box">
      <div class="content-box">
        <div class="img-bg">
          <img
            style="height: 100%; width: 100%"
            src="@/assets/images/notfoundBg.png"
            alt="nofound"
          />
        </div>
        <img
          class="cuise-img"
          src="@/assets/images/cuiseNot.png"
          alt="nofound"
        />
        <div class="order-box">
          <div class="order-title">{{ cuisineInfo.pageTitle }}</div>
          <div class="order-content">
            {{ cuisineInfo.popTitle }}
          </div>
        </div>
      </div>
      <div class="back-btn">
          <i class="el-icon-back"></i>
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
            <el-breadcrumb-item>
              <span style="cursor: default; font-weight: 600; color: #09ca6a"
                >Category</span
              >
            </el-breadcrumb-item>
          </el-breadcrumb>
      </div>
      <div class="order-search">
            <div class="delivery-method">
              <div
                :class="['type-btn', { 'btn-active': transType == ind + 1 }]"
                v-for="(item, ind) in typeList"
                :key="item"
                @click="transType = ind + 1"
              >
                <i
                  :class="['iconfont', iconList[ind], { font26: ind == 2 }]"
                ></i>
                <span>{{ item }}</span>
              </div>
            </div>
            <div class="find-bottom">
              <div class="find-input">
                <img
                  class="input-icon"
                  src="@/assets/images/location.png"
                  alt="location-img"
                />
                <input
                  class="input-content"
                  type="text"
                  :placeholder="$isUk?'Enter your postcode':'Enter your irecode'"
                  v-model="keywords"
                  @input="inputNow"
                  @keyup="inputNow"
                />
                <!-- 搜索结果内容 -->
                <div :class="['expand-box', { 'expand-box-active': keywords }]">
                  <div
                    class="search-item"
                    v-for="(item, ind) in resultList"
                    :key="ind"
                    @click="normalSearch(item)"
                  >
                    <span>{{ item.content }}</span>
                    <i
                      class="el-icon-loading"
                      v-if="itemLoading == item.place_id && item.type=='location'"
                    ></i>
                    <img
                      v-else
                      src="@/assets/images/right.png"
                      alt="right-arrow"
                    />
                  </div>
                  <div class="load-box" v-if="loading">
                    <span class="loader"></span>
                  </div>
                  <div
                    class="load-box"
                    v-else-if="!loading && !resultList.length"
                  >
                    No data.
                  </div>
                </div>
              </div>
              <button class="find-btn" @click="inputNow">
                <img
                  class="find-img"
                  src="@/assets/images/search.png"
                  alt="search-img"
                />
                Find Food
              </button>
            </div>
          </div>
      <div class="info">
        <div class="introduceOne">
          <div class="introduceOne-title">
            <!-- {{ cuisineInfo.pageTitle }}
            <div style="font-size:21px;margin-top: 20px">
              {{ cuisineInfo.popTitle }}
            </div> -->
            {{ cuisineInfo.popContent1 }}
          </div>
          <div class="info-content-box">
            <div class="introduceOne-content">
              <!-- <b>{{ cuisineInfo.popContent1 }} <br /></b> -->
              {{ cuisineInfo.popContent2 }} <br />
              {{ cuisineInfo.popContent3 }} <br />
            </div>
            <span style="flex: 1"></span>
            <img
              class="introduceOne-img"
              :src="cuisineInfo.popImg"
              alt="food"
            />
          </div>
        </div>
        <div class="popDishes">
          <div class="pop-dishes-title">Popular Dishes</div>
          <div class="pop-div">
            <div
              class="pop-dishesCard"
              v-for="(item, index) in popDishesArr"
              :key="index"
            >
              <div class="pop-dishesTitle">{{ item.popTitle }}</div>
              <img class="pop-dishesImg" :src="item.popImg" alt="food" />
              <div class="pop-dishesContent">
                {{ item.popContent1 }}<br /><br />
                {{ item.popContent2 }}
              </div>
            </div>
          </div>
        </div>
        <div class="comment-box">
          <div class="comment-title">Review from Consumer</div>
          <div class="comment-div">
            <div
              class="comment-card"
              v-for="(item, index) in commentPicArr"
              :key="index"
            >
            
              <img
                class="comment-icon"
                src="@/assets/images/quotation.png"
                alt="icon"
              />
            
            <viewer :images="[{img:item.commentPic}]"> 
              <img class="comment-img" :src="item.commentPic" alt="comments" />
            </viewer>
            </div>
          </div>
        </div>
        <div class="findBest-box">
          <div class="findBest-title">
              Find the Best {{this.$route.query.cuisineName}} Takeaways & Restaurants Near You
          </div>
          <div class="findBest-div">
              <div class="findBest-card" v-for="item in merchantList" :key="item.merchantId">
                <MerchantItem style="width:100%;" :item="item" @click="goMerchant(item)"></MerchantItem>
              </div>
          </div>
          <div class="viewAll" v-if="!merchantListLoading && merchantTotal<=3 && this.screenWidth<=950" @click="viewMoreMerchant">View All</div>
        </div>     
        <div class="discoverOther-box">
          <div class="discoverOther-title">
            Discover Other Cuisines
          </div>
          <div class="discoverOther-div">
              <div class="discoverOther-card"  v-for="items in foodTypeList" :key="items.cuisineId">
                <div class="discoverOther-category">
                  <img class="discoverOther-img" :src="items.featuredImage" alt="category"/>
                  <div class="discoverOther-name">{{ items.cuisineName }}</div>
                </div>
              </div>
          </div>
        </div>
        <FaqsBox></FaqsBox>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from "@/utils/debounce.js";
import FaqsBox from "@/components/IndexComps/FaqsBox.vue";
import MerchantItem from "@/components/MerchantItem.vue";
import cateInfo from './foodInfo';
import { postGateway } from '@/request/index.js';
export default {
  components: {
    FaqsBox,
    MerchantItem,
  },
  data() {
    return {
      transType: 1,
      typeList: ["Delivery", "Pick-up", "Dine-in"],
      iconList: ["icon-waimai", "icon-01_zitidian", "icon-tangshi"],
      keywords: "",
      resultList: [],
      loading: false,
      itemLoading: null,
      popDishesArr: [],
      commentPicArr: [
        {
          commentPic:
            "https://nweb.alleatapp.com/food/img/20230712/A9E9FD74EF42AE44F6560FBA6E212AA2.png",
        },
        {
          commentPic:
            "https://nweb.alleatapp.com/food/img/20230712/A9E9FD74EF42AE44F6560FBA6E212AA2.png",
        },
        {
          commentPic:
            "https://nweb.alleatapp.com/food/img/20230712/A9E9FD74EF42AE44F6560FBA6E212AA2.png",
        },
        {
          commentPic:
            "https://nweb.alleatapp.com/food/img/20230712/A9E9FD74EF42AE44F6560FBA6E212AA2.png",
        },
        {
          commentPic:
            "https://nweb.alleatapp.com/food/img/20230712/A9E9FD74EF42AE44F6560FBA6E212AA2.png",
        },
        {
          commentPic:
            "https://nweb.alleatapp.com/food/img/20230712/A9E9FD74EF42AE44F6560FBA6E212AA2.png",
        },
      ],
      cuisineInfo: {
        pageTitle: "",
        popTitle: "",
        popImg: "",
        popContent1: "",
        popContent2: "",
        popContent3:"",

      },
      foodTypeList: [], //分类数组
      nowCuiseId: '',  // 商家分类id
      merchantList:[], //商家数组
      merchantTotal:0,
      merchantListLoading:false,
      merchantPages: {
        pageNum:1,
        pageSize:3,
      },
      screenWidth:null, //屏幕宽度
    };
  },
  mounted(){
    this.screenWidth=document.body.clientWidth;
    window.onresize=()=>{
      return(()=>{
        this.screenWidth=document.body.clientWidth;
      })()
    }
  },
  watch:{
    screenWidth(val,oldval){
      // this.screenWidth=newWidth;
      // console.log(val,"当前值");
      // console.log(oldval,"旧值");
      if (val<=950 && oldval>950)
      {
        this.screenWidth=950;
        this.merchantPages.pageNum=1,
        this.merchantPages.pageSize=3,
        this.getCateMerchant();
      }
      else if(val<=950 && oldval<=950)
      {
        this.screenWidth=950;
      }
      else if(val>950 && oldval<=950){
        this.screenWidth=951;
        this.merchantPages.pageNum=1,
        this.merchantPages.pageSize=10,
        this.getCateMerchant();
      }
      else if(val>950 && oldval>950){
        this.screenWidth=951;
      }
    },
    '$route.query.cuisineId'(){
      // console.log(val,"分类新值");
      // console.log(oldval,"分类旧值");
      this.nowCuiseId = this.$route.query.cuisineId;
      let name = this.$route.query.cuisineName
      this.getFoodCategory();
      this.getCateMerchant();
      if (cateInfo[name] == undefined )
      {
        // console.log(cateInfo['Indian'],'默认显示');
        this.popDishesArr = cateInfo['Indian'].splice(1, 2);
        this.cuisineInfo = cateInfo['Indian'][0];
        
      }
      else
      {
        
        this.popDishesArr = cateInfo[name].splice(1, 2);
        this.cuisineInfo = cateInfo[name][0];
        // console.log(cateInfo[name][0],'返回的数组');
      }
    },
  },
  created() {
    // 获取页面参数
    // console.log(this.$route.query, 'this.$route.query')
    this.nowCuiseId = this.$route.query.cuisineId
    let name = this.$route.query.cuisineName
    this.getFoodCategory();
    this.getCateMerchant();
    if (cateInfo[name] == undefined )
    {
      // console.log(cateInfo['Indian'],'默认显示');
      this.popDishesArr = cateInfo['Indian'].splice(1, 2);
      this.cuisineInfo = cateInfo['Indian'][0];
      
    }
    else
    {
      
      this.popDishesArr = cateInfo[name].splice(1, 2);
      this.cuisineInfo = cateInfo[name][0];
      // console.log(cateInfo[name][0],'返回的数组');
    }
  },
  methods: {
    goMerchant(item) {
        console.log(item, 'item')
        this.$router.push({
            path: '/menu-' + item.restaurantSlug
        })
    },
    getFoodCategory() {  // 获取分类
      postGateway({
        url: "/customerWeb/index/popularCategory",
        method: "GET",
        data: {},
      }).then((res) => {
        this.foodTypeList = res.data;
        // console.log(this.foodTypeList,'this.foodTypeList')
      });
    },
    // 获取分类下的商家
    getCateMerchant(refresh=false) {
        this.merchantListLoading =true;
        if(refresh){
          this.merchantPages.pageNum=1;
          this.merchantPages.pageSize=10;
        }
        postGateway({
            url: '/customerApp/index/queryMerchantByPage',
            method: 'GET',
            data: {
                keyword: '',
                sort: '', 
                tastRating: '', 
                serviceRating: '', 
                discount: '',
                pageNum: this.merchantPages.pageNum,
                pageSize: this.merchantPages.pageSize,
                cuisineId: this.nowCuiseId,
                type: '',
                selfLontitude: '',
                selfLatitude: '',
                // selfLontitude: -0.0642075,
                // selfLatitude: 51.3627294,
            }
      }).then((res) => {
          this.merchantList = res.data.list;
          this.merchantListLoading=false;
          this.merchantTotal=res.data.list.length;
      }).catch(err => {
        this.$message.error(err)
      })
    },
    viewMoreMerchant(){
      if(this.merchantPages.pageSize==3){
        this.merchantPages.pageSize=10;
        this.getCateMerchant(true);
      }
      else{
        this.merchantPages.pageNum++;
        this.getCateMerchant();
      }
    },
    // 获取分类下的商家
    // getCateMerchant() {
    //     postGateway({
    //         url: '/customerApp/index/queryMerchantByPage',
    //         method: 'GET',
    //         data: {
    //             keyword: '',
    //             sort: '', 
    //             tastRating: '', 
    //             serviceRating: '', 
    //             discount: '',
    //             pageNum: 1,
    //             pageSize: 10,
    //             cuisineId: this.nowCuiseId,
    //             type: '',
    //             // selfLontitude: lng,
    //             // selfLatitude: lat,
    //             selfLontitude: -0.0642075,
    //             selfLatitude: 51.3627294,
    //         }
    //   }).then((res) => {
    //       this.merchantList = res.data.list;
    //   }).catch(err => {
    //     this.$message.error(err)
    //   })
    // },
    inputNow() {
      this.resultList = [];
      this.loading = true;
      debounce(() => {
        if (this.keywords.trim().length < 3) return;
        postGateway({
          method: "GET",
          url: "/customerApp/index/getPreKeyword",
          data: { keyword: this.keywords },
        })
          .then((res) => {
            if (res.data.length) {
              this.resultList = res.data.map((x) => {
                return {
                  content: x,
                  type: "content",
                };
              });
              this.loading = false;
            } else {
              this.googlePreSearch();
            }
          })
          .catch(() => {
            this.googlePreSearch();
          });
      }, 500);
    },
    googlePreSearch() {
      this.loading = true;
      if(this.$isUk) {
				postGateway({
					url: `/customerApp/addressioSearch/autocomplete`,
					method: 'GET',
					data: {
						keyword: this.keywords
					}
				}).then(res=>{
					console.log(res.data,'搜索地址会餐')
					this.loading = false;
					this.resultList = res.data.suggestions.map(x=>({
            ...x,
            content: x.description || x.address,
            place_id: x.id,
            type: "location",
          }));
				})
				.catch(err=>{
					this.loading = false;
					console.log(err,'搜索地址报错')
				})
				return
			}
      postGateway({
        method: "GET",
        url: "/customerWeb/googleSearch/autocomplete",
        data: { keyword: this.keywords },
      })
        .then((res) => {
          this.resultList = this.resultList.concat(
            res.data.predictions.map((x) => {
              return {
                ...x,
                content: x.description,
                type: "location",
              };
            })
          );
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    normalSearch(item) {
      if (item.type == "content") {
        this.$router.push({
          path: "/browse",
          query: {
            searchName: item.content,
            lng: "",
            lat: "",
            searchLoc: "",
          },
        });
      } else if (item.type == "location") {
        this.googleSearch(item);
      }
    },
    googleSearch(item) {
      this.itemLoading = item.place_id;

      if(this.$isUk) {
					postGateway({
						url: `/customerApp/addressioSearch/get`,
						method: 'GET',
						data: {
							id: item.id
						}
					}).then(res=>{
						console.log(res,'搜索地址会餐')
						let { town_or_city, longitude, latitude  } = res.data;
						this.$router.push({
              path: "/browse",
              query: {
                searchName: "",
                lng: longitude,
                lat: latitude,
                searchLoc: town_or_city,
                transType: this.transType,
              },
            });
            this.searchVisible = false;
					})
					.catch(()=>{
            this.searchVisible = false;
					})
					return
				}

      postGateway({
        method: "GET",
        url: "/customerWeb/googleSearch/placeGeoCode",
        data: {
          keyword: "",
          placeId: item.place_id,
        },
      }).then((res) => {
        this.itemLoading = '';
        if (res.code == 200) {
          this.$router.push({
            path: "/browse",
            query: {
              searchName: "",
              lng: res.data.lng,
              lat: res.data.lat,
              searchLoc: res.data.formattedAddress,
              transType: this.transType,
            },
          });
          this.searchVisible = false;
        } else {
          this.$message.error("Error happen...");
        }
      }).catch(err=>{
        this.itemLoading = '';
        console.log(err,'搜索报错')
      })
    },
    becomePart() {
      window.open("https://alleatapp.net/become-partner/");
    },
    aboutUs() {
      window.open("https://alleatapp.net/");
    },
    goLogin() {
      this.$router.replace({
        path: "/login",
      });
    },
    goHome() {
      this.$router.replace({
        path: "/",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.cate-box {
  min-height: 6000px;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  position: relative;
  zoom: 0.8;
  .content-box {
    position: relative;
    width: 100%;
    height: 620px;
    .img-bg {
      position: absolute;
      z-index: 0;
      height: 545px;
      width: 100%;
    }

    .cuise-img {
      height: 259px;
      width: 370px;
      position: absolute;
      z-index: 1;
      right: 6%;
      top: 0px;
    }
  }
  .order-box {
    width: 1900px;
    height: 500px;
    position: relative;
    margin-top:215px;
    margin-left: 5%;
    z-index: 1;
    .order-title {
      width: 100%;
      height: 80px;
      color: #fff;
      font-family: Roboto;
      font-size: 70px;
      font-style: normal;
      font-weight: 700;
      line-height: 100%; /* 80px */
    }
    .order-content {
      width: 100%;
      height: 70px;
      padding: 15px 0px;
      color: #fff;
      font-family: Roboto;
      font-size: 26px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 42px */
    }
    // .order-search {
    //   width: 1074px;
    //   height: 300px;
    //   border-radius: 15px;
    //   display: flex;
    //   padding: 24px;
    //   background-color: #fff;
    //   box-shadow: 1px 2px 15px #0a191e;
    //   position: absolute;
    //   .delivery-method {
    //     width: 100%;
    //     height: 123px;
    //     display: flex;
    //     position: relative;
    //     .type-btn {
    //       cursor: pointer;
    //       display: inline-flex;
    //       margin-right: 20px;
    //       height: 60px;
    //       border-radius: 4px;
    //       justify-content: center;
    //       width: 172px;
    //       align-items: center;
    //       background: #e9eaea;
    //       font-weight: bold;
    //       font-size: 24px;
    //       transition: all 0.2s ease-in-out 0s;

    //       i {
    //         color: #000000;
    //         margin-right: 8px;
    //         font-size: 26px;
    //       }
    //       .icon-tangshi {
    //         font-size: 20px;
    //       }
    //       &:hover {
    //         background: #cbcdcd;
    //         color: #fff;
    //       }
    //     }
    //     .btn-active {
    //       background: #09ca6a;
    //       color: #fff;

    //       &:hover {
    //         background: #049d51;
    //       }
    //       i {
    //         color: #fff;
    //         margin-right: 8px;
    //         font-size: 26px;
    //       }
    //     }
    //   }
    //   .find-bottom {
    //     width: 100%;
    //     display: flex;
    //     height: 188px;
    //     position: absolute;
    //     left: 0;
    //     top: 123px;
    //     padding: 24px;
    //     gap: 10px;
    //     .find-input {
    //       display: flex;
    //       position: absolute;
    //       width: 743px;
    //       height: 77px;
    //       padding: 7px 0px 8px 16px;
    //       align-items: center;
    //       gap: 12px;
    //       flex: 1 0 0;
    //       border-radius: 8px;
    //       background: #f5f5f5;
    //       .input-icon {
    //         position: absolute;
    //         left: 49px;
    //         top: 16.559px;
    //         width: 32px;
    //         height: 35px;
    //       }
    //       .input-content {
    //         position: absolute;
    //         width: 655px;
    //         left: 89px;
    //         top: 15.559px;
    //         background: #f5f5f5;
    //         color: #9e9e9e;
    //         font-family: Open Sans;
    //         font-size: 30px;
    //         font-style: normal;
    //         font-weight: 400;
    //         line-height: 140%; /* 42px */
    //       }
    //     }
    //     .expand-box {
    //       position: absolute;
    //       width: 100%;
    //       height: 0px;
    //       top: 60px;
    //       left: 0;
    //       background: rgba(255, 255, 255, 0.95);
    //       padding: 20px 0;
    //       height: 0px;
    //       opacity: 0;
    //       transition: all 0.8s ease-in-out 0s;

    //       .search-item {
    //         height: 50px;
    //         display: flex;
    //         align-items: center;
    //         border-bottom: 1px solid #e9e9e9;
    //         padding: 0 20px;
    //         cursor: pointer;

    //         &:hover {
    //           background-color: rgba(236, 230, 230, 0.95);
    //         }

    //         span {
    //           flex: 1;
    //           font-size: 20px;
    //         }
    //         .el-icon-loading {
    //           margin-right: 15px;
    //         }

    //         img {
    //           height: 18px;
    //           width: 25px;
    //         }
    //       }
    //     }

    //     .load-box {
    //       height: 100%;
    //       width: 100%;
    //       display: flex;
    //       align-items: center;
    //       justify-content: center;
    //       font-size: 24px;
    //       .loader {
    //         width: 48px;
    //         height: 48px;
    //         border: 5px solid #fff;
    //         border-bottom-color: #ff3d00;
    //         border-radius: 50%;
    //         display: inline-block;
    //         box-sizing: border-box;
    //         animation: rotation 1s linear infinite;
    //       }

    //       @keyframes rotation {
    //         0% {
    //           transform: rotate(0deg);
    //         }
    //         100% {
    //           transform: rotate(360deg);
    //         }
    //       }
    //     }

    //     .expand-box-active {
    //       height: 300px;
    //       opacity: 1;
    //       overflow-y: scroll;
    //     }

    //     input {
    //       background: transparent;
    //       font-weight: normal;
    //     }

    //     img {
    //       height: 20px;
    //       width: 20px;
    //       margin-right: 10px;
    //     }
    //     .find-btn {
    //       display: flex;
    //       //  width:267px;
    //       height: 75px;
    //       padding: 21px 48px;
    //       justify-content: center;
    //       align-items: center;
    //       position: absolute;
    //       left: 73%;
    //       gap: 10px;
    //       border-radius: 8px;
    //       background: #09ca6a;
    //       box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    //       cursor: pointer;
    //       color: #fff;
    //       text-align: center;
    //       font-family: Source Sans Pro;
    //       font-size: 30px;
    //       font-style: normal;
    //       font-weight: 700;
    //       line-height: 100%; /* 30px */
    //       .find-img {
    //         width: 30px;
    //         height: 30px;
    //       }
    //     }
    //   }
    // }
  }
  .back-btn {
    position: absolute;
    background: #0a191e;
    height: 36px;
    border-radius: 18px;
    padding: 0 20px;
    font-weight: bold;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    margin: 30px 6% 0;
    .el-icon-back {
      color: #c2f93a;
      font-size: 22px;
      margin-right: 10px;
    }
    :deep(.el-breadcrumb__inner) {
      color: #fff;
    }
    :deep(.el-breadcrumb__separator) {
      color: #fff;
    }
  }
 .order-search {
    width: 1074px;
    height: 300px;
    border-radius: 15px;
    display: flex;
    padding: 24px;
    background-color: #fff;
    box-shadow: 1px 2px 15px #0a191e;
    position: absolute;
    top:405px;
    left:5%;
    .delivery-method {
      width: 100%;
      height: 123px;
      display: flex;
      position: relative;
      .type-btn {
        cursor: pointer;
        display: inline-flex;
        margin-right: 20px;
        height: 60px;
        border-radius: 4px;
        justify-content: center;
        width: 172px;
        align-items: center;
        background: #e9eaea;
        font-weight: bold;
        font-size: 24px;
        transition: all 0.2s ease-in-out 0s;

        i {
          color: #000000;
          margin-right: 8px;
          font-size: 26px;
        }
        .icon-tangshi {
          font-size: 20px;
        }
        &:hover {
          background: #cbcdcd;
          color: #fff;
        }
      }
      .btn-active {
        background: #09ca6a;
        color: #fff;

        &:hover {
          background: #049d51;
        }
        i {
          color: #fff;
          margin-right: 8px;
          font-size: 26px;
        }
      }
    }
    .find-bottom {
      width: 100%;
      display: flex;
      height: 188px;
      position: absolute;
      left: 0;
      top: 123px;
      padding: 24px;
      gap: 10px;
      .find-input {
        display: flex;
        position: absolute;
        width: 743px;
        height: 77px;
        padding: 7px 0px 8px 16px;
        align-items: center;
        gap: 12px;
        flex: 1 0 0;
        border-radius: 8px;
        background: #f5f5f5;
        .input-icon {
         position: absolute;
          left: 49px;
          top: 16.559px;
          width: 32px;
          height: 35px;
        }
        .input-content {
          position: absolute;
          width: 655px;
          left: 89px;
          top: 15.559px;
          background: #f5f5f5;
          color: #9e9e9e;
          font-family: Open Sans;
          font-size: 30px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%; /* 42px */
        }
      }
      .expand-box {
        position: absolute;
        width: 100%;
        height: 0px;
        top: 60px;
        left: 0;
        background: rgba(255, 255, 255, 0.95);
        padding: 20px 0;
        height: 0px;
        opacity: 0;
        transition: all 0.8s ease-in-out 0s;

        .search-item {
          height: 50px;
          display: flex;
          align-items: center;
          border-bottom: 1px solid #e9e9e9;
          padding: 0 20px;
          cursor: pointer;

          &:hover {
            background-color: rgba(236, 230, 230, 0.95);
          }

          span {
            flex: 1;
            font-size: 20px;
          }
          .el-icon-loading {
            margin-right: 15px;
          }

          img {
            height: 18px;
            width: 25px;
          }
        }
     }

     .load-box {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        .loader {
          width: 48px;
          height: 48px;
          border: 5px solid #fff;
          border-bottom-color: #ff3d00;
          border-radius: 50%;
          display: inline-block;
          box-sizing: border-box;
          animation: rotation 1s linear infinite;
        }

        @keyframes rotation {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      }

      .expand-box-active {
        height: 300px;
        opacity: 1;
        overflow-y: scroll;
      }

      input {
        background: transparent;
        font-weight: normal;
      }

      img {
        height: 20px;
        width: 20px;
        margin-right: 10px;
      }
      .find-btn {
        display: flex;
        //  width:267px;
        height: 75px;
        padding: 21px 48px;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 73%;
        gap: 10px;
        border-radius: 8px;
        background: #09ca6a;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        cursor: pointer;
        color: #fff;
        text-align: center;
        font-family: Source Sans Pro;
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%; /* 30px */
        .find-img {
          width: 30px;
          height: 30px;
        }
      }
    }
  }
  .info {
    width: 100%;
    // height: 1000px;
    position: relative;
    z-index: 0;
    margin-top: 100px;
    margin-bottom: 20px;
    .introduceOne {
      width: 1200px;
      top: 150px;
      // height: 498px;
      margin: 30px auto;
      align-items: center;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      border-radius: 24px;
      background: #fff;
      .introduceOne-title {
        font-family: Roboto;
        font-size: 35px;
        font-weight: bold;
        color: black;
        text-align: center;
        padding-top: 20px;
      }
      .info-content-box {
        display: flex;
        justify-content: space-between;
        padding: 40px 32px;
        .introduceOne-content {
          top: 120px;
          left: 40px;
          width: 550px;
          // height: 100px;
          color: #212121;
          font-family: Roboto;
          font-size: 21px;
          font-style: normal;
          font-weight: 400;
          line-height: 30px; /* 142.857% */
        }
        .introduceOne-img {
          top: 120px;
          left: 660px;
          width: 540px;
          height:280px;
          border-radius: 16px;
        }
      }
    }
    .popDishes {
      margin-top: 100px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .pop-dishes-title {
        width: 1400px;
        color: #333;
        font-family: Inter;
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: 36.8px; /* 92% */
      }

      .pop-div {
        margin: 50px auto;
        width: 1400px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        .pop-dishesCard {
          width: 650px;
          display: flex;
          align-items: center;
          flex-direction: column;
          flex-wrap: nowrap;
          padding: 30px;
          // height: 800px;
          border-radius: 24px;
          background: #fff;
          box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

          .pop-dishesTitle {
            height: 100px;
            color: #333;
            font-family: Inter;
            font-size: 21px;
            font-style: normal;
            font-weight: 700;
            line-height: 36.8px; /* 115% */
          }
          .pop-dishesContent {
            margin-top: 50px;
            color: #212121;
            font-family: Roboto;
            font-size: 21px;
            font-style: normal;
            font-weight: 400;
            line-height: 30px; /* 142.857% */
          }
          .pop-dishesImg {
            width: 100%;
            height: 300px;
            border-radius: 18px;
            display: flex;
          }
        }
      }
    }
    .comment-box {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px;
      .comment-title {
        color: #333;
        font-family: Inter;
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: 36.8px; /* 92% */
        text-align: center;
        padding: 25px;
      }
      .comment-div {
        width: 1800px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
        .comment-card {
          // width:550px;
          flex: 0 0 28%;
          position: relative;
          border-radius: 10px;
          background: #fff;
          padding: 10px;
          // margin-right: 10px;
          margin-bottom: 50px;
          box-shadow: 0px 0px 10px 4px rgba(31, 66, 135, 0.1);
          .comment-icon {
            position: absolute;
            right: 10px;
            width: 60px;
            height: 60px;
          }
          .comment-img {
            width: 100%;
            cursor: pointer;
          }
        }
      }
    }
    .findBest-box {
      // Find the Best Chinese Takeaways & Restaurants Near You
      display: flex;
      flex-direction: column;
      .findBest-title {
        margin-left: 100px;
        color: #333;
        font-family: Inter;
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: 36.8px; /* 92% */
      }
      .findBest-div {
        display: flex;
        margin: 20px 10%;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        .findBest-card {
          padding: 0px 20px;
          flex:0 0 25%;
          min-height: 358px;
          margin-bottom: 40px;
          border-radius: 12px 12px 0px 0px;
        }
      }
      .viewAll {
        height: 54px;
        width: 140px;
        margin: auto;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        background: #09CA6A;
        border-radius: 37px;
        font-size: 30px;
        font-weight: 700;
        color: #fff;
        cursor: pointer;
        transition: all .2s ease-in-out;
        
        &:hover {
          background: #09b35e;
        }
        }
    }
    .discoverOther-box {
      display: flex;
      flex-direction: column;
      .discoverOther-title {
        margin-left: 100px;
        color: #333;
        font-family: Inter;
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: 36.8px; /* 92% */
      }
      .discoverOther-div {
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        background: #FFF;
        
        .discoverOther-card {
          // width:234px;
          // height: 234px;
          border-radius: 16px;
          display: flex;
          flex:0 0 17%;
          padding: 20px 0px;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          align-content: flex-end;
          .discoverOther-category {
            width:234px;
            height: 234px;
            border-radius: 16px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            align-content: flex-end;
            box-shadow: 0px 0px 5px 0px #BBB;
            .discoverOther-img{
              width:192px;
              height: 185px;
            }
            .discoverOther-name{
              display:flex;
              flex-wrap: wrap;
              align-content: center;
              align-items: baseline;
              justify-content: center;
              width: 100%;
              height: 48px;
              border-radius: 0px 0px 16px 16px;
              background: #FFF;
              color: #000;
              font-family: Roboto;
              font-size: 30px;
              font-style: normal;
              font-weight: 700;
              line-height: 14px; /* 46.667% */
              justify-content: center;
            }
          }
          
          
        }
      }
      
    }
  }
}

@media screen and (min-width:1280px) and (max-width:1600px) {
  .cate-box {
    .content-box {
      .order-box {
        padding: 0px 0px 0px 3%;
        z-index: 1;
        width:100%;
        height: auto;
        margin-left: auto;
        .order-title {
          font-size: 62px;
          height: auto;
        }
        .order-content {
          font-size: 25px;
          height: auto;
        }
      }
    }
    .order-search {
      top:405px;
      left:3%;
    }
    .info {
      .comment-box {
        .comment-div {
          width: auto;
        }
      }
    }
 }
 
}

@media screen and (min-width:951px) and (max-width: 1279px) {
  .cate-box {
    .content-box {
      .order-box {
        padding: 0px 0px 0px 3%;
        z-index: 1;
        width:100%;
        height: auto;
        margin-left: auto;
        margin-top:245px;
        .order-title {
          font-size: 60px;
          height: auto;
          line-height: 85%;
        }
        .order-content {
          font-size: 22px;
          height: auto;
        }
      }
    }
    .order-search {
      top:405px;
      left:3%;
    }
    .info {
      .comment-box {
        .comment-div {
          width: auto;
        }
      }
    }
 }
}

@media screen and (max-width:950px) {
  .cate-box {
    background: #f5f5f5;
    height: unset;
    min-height: unset;
    .content-box {
      // background: #f5f5f5;
      height:auto;
      .img-bg{
        height: 100%;
      }
      .cuise-img {
        height:100px;
        width:145px;
        z-index:0;
      }
    
      .order-box {
        width:100%;
        height: auto;
        position: relative;
        padding: 10px 20px;
        margin-top:50px;
        margin-left:auto;
        .order-title {
          font-size: 26px;
          height: auto;
        }
        .order-content {
          font-size: 12px;
          height: auto;
          padding: 10px 0px;
        }
      }
    }
    .back-btn {
      position: relative;
      margin-left: 1%;
      margin-top: 20px;
      width: 70%;
    }
    .order-search {
      position: relative;
      width:98%;
      height: auto;
      top:0px;
      left:0px;
      margin: 20px auto;
      padding: 15px;
      flex-direction: column;
      .delivery-method {
        height: auto;
         .type-btn {
          height: 50px;
          margin-right: 15px;
          font-size: 20px;
         }
      }
      .find-bottom {
        position: relative;
        left: 0px;
        top:0px;
        margin-top: 25px;
        padding: 15px 0px;
        height: auto;
        .find-input {
          width:80%;
          height: auto;
          position: relative;
          padding: 8px 0px;
          .input-icon {
            position: relative;
            left:0px;
            top:0px;
            width:25px;
            height: 25px;
          }
          .input-content {
            position: relative;
            left:0px;
            top:0px;
            font-size: 20px;
            width:100%;
          }
        }
        .find-btn {
          height: auto;
          position: relative;
          left:0px;
          font-size: 20px;
          padding: 8px 5px;
          gap: 8px;
          .find-img {
            width: 20px;
            height:20px;
            margin-right: auto;
          }
        }
      }
    }
    .info {
      width: 100%;
      background: #f5f5f5;
      // top: 10px;
      height: unset;
      margin-top: 20px;
      .introduceOne {
        margin: 0px auto;
        width:95%;
        .introduceOne-title {
          font-size: 30px;
        }
        .info-content-box {
          flex-direction: column-reverse;
          justify-content: center;
          align-items: center;
          padding: 40px 10px;
          .introduceOne-img {
            width:100%;
            height: 90%;
          }
          .introduceOne-content {
            margin-top:30px;
            width:100%;
          }
          
        }
      }
      .popDishes {
        margin-top: 20px;
        width:100%;
        background: #f5f5f5;
        .pop-dishes-title {
          width: 100%;
          padding: 10px;
        }
        .pop-div {
          width: 100%;
          padding: 10px;
          flex-direction: column;
          margin-top: 0px;
          .pop-dishesCard {
            width: 100%;
            align-items: flex-start;
            padding: 10px;
            margin-top: 10px;
            .pop-dishesTitle {
              height: auto;
            }
            .pop-dishesImg {
              margin-top: 10px;
              height: auto;
            }
            .pop-dishesContent {
              margin-top: 5%;
            }
          }
        }
      }
      .comment-box {
        padding: 10px;
        .comment-title{
          font-size: 35px;
          padding: 0px 10px 10px 10px;
        }
        .comment-div {
          display: flex;
          flex-direction: column;
          align-items: center;
          width:100%;
          .comment-card {
            margin:5px 10px;
            .comment-icon {
              width: 50px;
              height: 50px;
            }
          }
        }
      }
      .findBest-box {
        margin-top: 20px;
        align-items: center;
        .findBest-title {
          font-size: 35px;
          padding: 5px;
          margin-left:0px;
          margin-bottom: 15px;
          text-align: center;
        }
        .findBest-div {
          display: flex;
          flex-direction: column;
          width:100%;
          margin: 0px;
          .findBest-card {
            margin-bottom: 20px;
          }
        }
      }
      .discoverOther-box{
        margin-top: 30px;
        display: flex;
        align-items: center;
        .discoverOther-title {
          margin-left:0px;
          font-size: 35px;
        }
        .discoverOther-div { 
          background:#f5f5f5;
          justify-content: space-evenly;
          .discoverOther-card{
            padding: 20px 4px;
            .discoverOther-category{
              width:210px;
              height: 200px;
              background: #FFF;
              .discoverOther-img {
                width:140px;
                height: 140px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
