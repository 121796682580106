<template>
    <div class="overlay" v-if="showClosed || showBusy">
        <!-- Closed Shop -->
        <div class="card" v-if="showClosed">
            <div class="card-header">
                <h3>This shop is currently closed</h3>
                <i class="el-icon-close close-icon" @click="showClosed=false" ></i>
            </div>
            <div class="card-body">
                <div class="info-block">
                    <i class="icon">🕒</i>
                    <div>
                        <p class="label">Closed period</p>
                        <!-- <p>From March 16, 2025 at 6:19 PM</p>
                        <p>To March 20, 2025 at 6:19 PM</p> -->
                        <p>From {{ formatTimeString(specialObj.startTime) }}</p>
                        <p>To {{ formatTimeString(specialObj.endTime) }}</p>
                    </div>
                </div>
                <div v-if="specialObj.nextStartTime" style="padding-left: 43px;border-top: 1px solid rgb(196 187 187);padding-top: 18px;margin-top: 12px;">
                    <p class="label">Reopening</p>
                    <!-- <p>March 21, 2025 at 3:19 PM</p> -->
                    <p>{{ formatTimeString(specialObj.nextStartTime) }}</p>
                </div>
                <span class="tag closed">CLOSED</span>
            </div>
            <div class="card-footer">
                <p class="desc">
                    <i class="el-icon-warning-outline"></i>
                    Sorry for the inconvenience. Please check back later or explore other shops.
                </p>
                <button class="primary" @click="goBrowse" >Browse Other Shops</button>
            </div>
        </div>

        <!-- Too Busy Shop -->
        <div class="card" v-if="showBusy">
            <div class="card-header">
                <h3>This shop is currently too busy</h3>
                <i class="el-icon-close close-icon" @click="showBusy=false" ></i>
            </div>
            <div class="card-body">
                <p class="label">Please check back in</p>
                <div class="countdown">
                    <div class="time-box">
                        <div class="number">{{ countdown.hours }}</div>
                        <div class="unit">hours</div>
                    </div>
                    <div class="time-box">
                        <div class="number">{{ countdown.minutes }}</div>
                        <div class="unit">minutes</div>
                    </div>
                    <div class="time-box">
                        <div class="number">{{ countdown.seconds }}</div>
                        <div class="unit">seconds</div>
                    </div>
                </div>
                <span class="tag busy">TOO BUSY</span>
            </div>
            <div class="card-footer">
                <p class="desc">
                    <i class="el-icon-warning-outline"></i>
                    Sorry for the inconvenience. Please check back later after the given period of time or explore other
                    shops.
                </p>
                <button class="primary" @click="showBusy=false" >Choose Other Time</button>
            </div>
        </div>
    </div>
</template>

<script>
import { postGateway } from '@/request';
export default {
    data() {
        return {
            countdownTarget: new Date(),
            countdown: {
                hours: '00',
                minutes: '00',
                seconds: '00'
            },
            intervalId: null,
            showClosed: false,
            showBusy: false,
            specialObj: {
                endTime: '',
                startTime: '',
                nextStartTime: '',
                type: ''
            }
        };
    },
    props: {
        merInfo: {
            type: Object,
            default: () => { }
        },
        transType: {
            type: String,
            default: ''
        }
    },
    mounted() {
        
        if (window.__PRERENDER_INJECTED && window.__PRERENDER_INJECTED.isSsg) {
            console.log('✨ 当前是 prerender 打包环境');
        } else {
            this.getMerchantStatus();
        }
    },
    beforeDestroy() {
        clearInterval(this.intervalId);
    },
    methods: {
        goBrowse() {
            if(location.href.includes('alleatapp.com')) {
                this.$router.push('/browse')
            } else {
                location.href = "https://alleatapp.com/browse/"
            }
            
        },
        getMerchantStatus() {
            postGateway({
                url: "/merchant/merchantOperateTime/getMerchantDetailHoliday",
                method: "GET",
                data: {
                    merchantId: this.merInfo.merchantId,
                    type: this.merInfo.holidayType,
                    transType: this.transType,
                }
            }).then(res => {
                console.log(res, '商家状态');
                if(res.data && res.data.length) {
                    let obj = res.data[0];
                    this.specialObj = obj;
                    if(obj.type == '3') {
                        this.showBusy = true;
                        this.countdownTarget = new Date(obj.endTime);
                        this.startCountdown();
                    }
                    if(obj.type == '1') {
                        this.showClosed = true;
                    }
                }
            })
            .catch(err => {
                console.log(err, '报错')
            })
        },
        formatTimeString(input='') {
            const date = input && new Date(input.replace(/-/g, '/')); // 兼容 iOS

            const options = {
                month: 'long',     // March
                day: 'numeric',    // 25
                year: 'numeric',   // 2025
                hour: 'numeric',   // 11 PM
                minute: '2-digit', // 00
                hour12: true       // AM/PM
            };

            const formatted = new Intl.DateTimeFormat('en-US', options).format(date);
            return formatted && formatted.replace(',', '') // 去掉年月之间的逗号
                .replace(/(\d{4})/, '$1 at') || '';   // 把 year 后面加上 "at"
        },
        startCountdown() {
            this.updateCountdown();
            this.intervalId = setInterval(this.updateCountdown, 1000);
        },
        updateCountdown() {
            const now = new Date();
            const diff = this.countdownTarget - now;

            if (diff <= 0) {
                this.countdown = { hours: '00', minutes: '00', seconds: '00' };
                clearInterval(this.intervalId);

                // ✅ 倒计时结束后刷新页面
                setTimeout(() => {
                    this.showBusy = false;
                    try {
                        if(new Date().valueOf() - new Date(this.specialObj.endTime).valueOf() < 10000) {
                            location.reload();
                        }
                    } catch (error) {
                        console.log(error,'error');
                    }
                }, 500); // 加一点延迟更平滑，也可省略

                return;
            }

            const totalSeconds = Math.floor(diff / 1000);
            const hours = Math.floor(totalSeconds / 3600);
            const minutes = Math.floor((totalSeconds % 3600) / 60);
            const seconds = totalSeconds % 60;

            this.countdown.hours = String(hours).padStart(2, '0');
            this.countdown.minutes = String(minutes).padStart(2, '0');
            this.countdown.seconds = String(seconds).padStart(2, '0');
        }

    }
};
</script>

<style lang="scss" scoped>
.overlay {
    position: absolute;
    z-index: 2000;
    height: 100%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    justify-content: center;
    align-items: flex-start;
    background: rgba(0, 0, 0, 0.5);
    padding: 48px 16px;
    min-height: 100vh;
    padding-top: 13%;
    box-sizing: border-box;
}

.card {
    position: fixed;
    background: #ffffff;
    border-radius: 12px;
    width: 100%;
    max-width: 520px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);

    .card-header {
        position: relative;
        padding: 20px;
        font-weight: bold;

        h3 {
            font-size: 18px;
            margin: 0;
            font-weight: bold;
        }

        .close-icon {
            position: absolute;
            right: 20px;
            top: 20px;
            font-size: 24px;
            cursor: pointer;
        }
    }

    .card-body {
        background: #c8f6df;
        padding: 24px;
        margin: 12px 12px 0;
        border-radius: 8px;
        position: relative;

        .tag {
            position: absolute;
            top: 0px;
            right: 0px;
            padding: 8px 12px;
            font-size: 16px;
            border-radius: 4px;
            color: white;
            font-weight: bold;

            &.closed {
                background: #e53935;
            }

            &.busy {
                background-color: #fa8c16;
            }
        }

        .info-block {
            display: flex;
            gap: 12px;

            .icon {
                font-size: 20px;
                margin-top: 2px;
                font-style: normal;
                margin-right: 12px;
            }

            .label {
                font-weight: bold;
            }

            p {
                margin-block-start: 0px;
                margin-block-end: 0px;
                margin: 8px 0px;
            }
        }

        .label {
            font-weight: bold;
            margin-bottom: 16px;
            font-size: 16px;
            margin-block-start: 0px;
        }

        .countdown {
            display: flex;
            gap: 12px;
            flex-wrap: wrap;

            .time-box {
                background: #63e5a5;
                padding: 12px 16px;
                border-radius: 10px;
                text-align: center;
                flex: 1 1 80px;

                .number {
                    font-size: 24px;
                    font-weight: bold;
                }

                .unit {
                    font-size: 12px;
                }
            }
        }
    }

    .card-footer {
        padding: 0px 16px 24px;
        font-size: 14px;
        color: #666;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;

        p {
            text-align: left;
            width: 100%;
        }

        .primary {
            background: #26d07c;
            color: white;
            width: 100%;
            max-width: 260px;
            padding: 10px 20px;
            border: none;
            border-radius: 20px;
            font-size: 14px;
            font-weight: bold;
            cursor: pointer;
            transition: background 0.3s;

            &:hover {
                background: #00b140;
            }
        }
    }
}

@media (max-width: 600px) {
    .overlay {
        flex-direction: column;
        align-items: center;
        padding: 24px 16px;
    }

    .card {
        top: 12%;
        width: 88%;
        margin-bottom: 24px;
    }
}
</style>