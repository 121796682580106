<template>
    <div class="menu-content">
        <div class="distance-box">
            <i class="el-icon-place"></i>
            <span v-if="info.deliveryDistance">Distance Covered: {{ deliveryDistance }}</span>
            <span v-else>PICK UP ONLY</span>
            <span style="flex:1;"></span>
            <!-- <span>£2 delivery fee</span> -->
        </div>
        <div class="menu-title">
            <h2>Menu</h2>
        </div>

        <!--  搜索结果展示 -->
        <div class="search-box" v-if="showSearchCover">
            <div class="cate-box">
                <div class="food-header food-item">
                    <div class="item-name">Item</div>
                    <!-- <div class="item-price"> Price</div> -->
                    <div class="item-order"> Order </div>
                </div>
                <section class="food-box" v-if="searchResult.length">
                    <div class="food-item" v-for="food in searchResult" :key="food.itemId">
                        <div class="item-name">
                            <div class="food-img" v-if="food.photo">
                                <viewer :images="[food.photo]"> 
                                    <img v-if="food.photo" :src="food.photo" style="object-fit: contain;" alt="">
                                </viewer>
                            </div>
                            <div>
                                <span class="name">{{ food.itemName }}</span>
                                <template v-if="food.foodEmoji" >
                                    <el-tooltip class="item" effect="dark" :content="food.foodEmojiDesc" placement="bottom">
                                        <span class="name-desc" >{{ food.foodEmoji }}</span>
                                    </el-tooltip>
                                </template> <br />
                                <span class="desc">{{ food.itemDescription }}</span>
                                <div class="price"> 
                                    {{ currencySign }}{{ food.price?(food.price*1).toFixed(2):food.price }} 
                                </div>
                            </div>
                        </div>
                        <!-- <div class="item-price"> {{ currencySign }}{{ food.price?food.price.toFixed(2):food.price }} </div> -->
                        <div class="item-order" v-if="addCartLoading == food.itemId">
                            <i class="el-icon-loading"></i>
                        </div>
                        <div class="item-order" v-else>
                            <div class="add-btn" @click="addCart(food, true)" 
                                v-if="food.qty<=0 && !( (food.foodSkuPriceList&&food.foodSkuPriceList.length>1) || food.isAddon)">
                                <i class="el-icon-plus"></i>
                                <span>Add</span>
                            </div>
                            <div class="add-btn add-addon" @click="addCart(food, true)" 
                                v-if="(food.foodSkuPriceList&&food.foodSkuPriceList.length>1) || food.isAddon">
                                <span>Add</span>
                            </div>
                            <div class="add-btn update-btn" v-else-if="food.qty>0">
                                <i class="el-icon-minus" @click="addCart(food, false)"></i>
                                <span class="qty">{{ food.qty }}</span>
                                <i class="el-icon-plus" @click="addCart(food, true)"></i>
                            </div>
                        </div>
                    </div>
                </section>
                <section v-else-if="searchLoading" class="loading-box">
                    <i class="el-icon-loading" ></i>
                </section>
                <section v-else class="loading-box">
                    <img class="empty-img" src="@/assets/menu/empty_cart2.png" alt="empty">
                </section>
            </div>
        </div>
        <div v-show="!showSearchCover">
            <div v-for="item in categoryList" :key="item.catId" class="cate-box" :data-cateId="item.catId" :id="'catId-' + item.catId"  >
                <h2>{{ item.categoryName }}</h2>
                <div class="desc" v-if="item.categoryDescription">{{ item.categoryDescription }}</div>
                <div v-else style="height: 10px;" ></div>
                <div class="food-header food-item">
                    <div class="item-name">Item</div>
                    <!-- <div class="item-price"> Price</div> -->
                    <div class="item-order"> Order </div>
                </div>
                <section class="food-box" v-if="item.foodList.length">
                    <div class="food-item" v-for="food in item.foodList" :key="food.itemId">
                        <div class="item-name">
                            <div class="food-img" v-if="food.photo">
                                <viewer :images="[food.photo]"> 
                                    <img v-if="food.photo" :src="food.photo" style="object-fit: contain;" alt="">
                                </viewer>
                            </div>
                            <div>
                                <span class="name">{{ food.itemName }}</span>
                                <template v-if="food.foodEmoji" >
                                    <el-tooltip class="item" effect="dark" :content="food.foodEmojiDesc" placement="bottom">
                                        <span class="name-desc" >{{ food.foodEmoji }}</span>
                                    </el-tooltip>
                                </template> <br />
                                <span class="desc">{{ food.itemDescription }}</span>
                                <div class="price"> 
                                    <span class="origin-price" v-if="food.originPrice" >{{ currencySign }}{{ food.originPrice?(food.originPrice*1).toFixed(2):food.originPrice }}</span>
                                    {{ currencySign }}{{ food.price?(food.price*1).toFixed(2):food.price }} 
                                </div>
                            </div>
                        </div>
                        <!-- <div class="item-price"> 
                            {{ currencySign }}{{ food.price?food.price.toFixed(2):food.price }} 
                        </div> -->
                        <div class="item-order" v-if="addCartLoading == food.itemId">
                            <i class="el-icon-loading"></i>
                        </div>
                        <div class="item-order" v-else>
                            <div class="add-btn" @click="addCart(food, true)" 
                                v-if="food.qty<=0 && !((food.foodSkuPriceList&&food.foodSkuPriceList.length>1) || food.isAddon)">
                                <i class="el-icon-plus"></i>
                                <span>Add</span>
                            </div>
                            <div class="add-btn" @click="addCart(food, true)" 
                                v-if="(food.foodSkuPriceList && food.foodSkuPriceList.length>1) || food.isAddon">
                                <span>Add</span>
                            </div>
                            <div class="add-btn update-btn" v-else-if="food.qty>0">
                                <i class="el-icon-minus" @click="addCart(food, false)"></i>
                                <span class="qty">{{ food.qty }}</span>
                                <i class="el-icon-plus" @click="addCart(food, true)"></i>
                            </div>
                        </div>
                    </div>

                    <!-- <div class="menu-load-mask" >
                        <el-skeleton animated >
                            <template slot="template">
                                <el-skeleton-item variant="text"  animated style="width: 100%; height: 80px;" />
                                <i class="el-icon-loading" ></i>
                            </template>
                            
                        </el-skeleton>
                    </div> -->
                </section>
                <section v-else-if="!item.foodList.length && item.loadDone!=true" class="loading-box">
                    <i class="el-icon-loading" ></i>
                </section>
            </div>
        </div>

        <!-- 多价格规格弹窗展示 -->
        <el-dialog
            :visible.sync="showModal"
            :width="isMobile?'90%':'30%'"
            :before-close="handleClose">
            <div slot="title" class="modal-title">
                {{ nowChooseFood.itemName }}
            </div>
            <div class="modal-content" >
                <div class="price-title" >Specifications</div>
                <ul>
                    <li v-for="(price,ind) in nowChooseFood.foodSkuPriceList" :key="ind" @click="nowChoosePrice(price, ind)"
                        :class="['price-item',{'price-item-active': nowPrice.sizeId == price.sizeId}]">
                        <span>{{ price.sizeName }}</span>
                        <div>
                            <span class="old-price" v-if="price.originPrice" >{{ currencySign + price.originPrice }}</span>
                            <span >{{ currencySign + price.foodPrice }}</span>
                        </div>
                    </li>
                </ul>
                <div class="dash-row" v-if="showAddonTitle"></div>
                <div class="price-title" v-if="showAddonTitle">Additional items</div>
                
                <div class="food-loading" v-if="loadingFood">
                    <i class="el-icon-loading" ></i>
                </div>
                <template v-else>
                    <!-- 有价格 的 addon -->
                    <ul v-if="foodDetails.addOnInfoList && foodDetails.addOnInfoList.length">
                        <li v-for="(value, key) in foodDetails.addOnInfoList" :key="value.addonId">
                            <div class="addon-title">
                                {{ value.addonCatName}}
                                ({{[0,1].includes(value.selectType)?'Single Choice':'Multiple Select'}}  {{value.required?'| '+ 'required':''}}) 
                                <span v-if="value.selectType==3" >Max choose {{value.customSize}}</span>
                            </div>
                            <div class="addon-box" >
                                <div :class="['price-item-addon',{'price-item-active':item.beingChoose}]" @click="chooseAddon(key,index)" :key="index" 
                                    v-for="(item,index) in value.subItemList" >
                                {{ item.subItemName }} | {{currencySign}}{{item.addonPrice.toFixed(2)}}
                                </div>
                            </div>
                        </li>
                    </ul>
                    <!-- 配菜 无价格 -->
                    <div class="addon-title" v-if="foodDetails.ingredientsList && foodDetails.ingredientsList.length>0">{{i18n.AddonItem}}</div>
                    <div class="addon-box" >
                        <div :class="['price-item-addon',{'price-item-active':nowChooseIdent.includes(item.ingredientsId)}]"
                            v-for="(item) in foodDetails.ingredientsList"  :key="item.ingredientsId" @click="chooseIdent(item)" >
                            {{ item.ingredientsName }}
                        </div>
                    </div>
                    
                    <!-- 口味 烹饪参考 -->
                    <div class="addon-title" v-if="foodDetails.cookingRefList&&foodDetails.cookingRefList.length>0">{{i18n.Cuise}}</div>
                    <div class="addon-box" >
                        <div :class="['price-item-addon',{'price-item-active':nowCookingRef.includes(item.cookId)}]"
                            v-for="(item) in foodDetails.cookingRefList" :key="item.cookId" @click="chooseCookingRef(item)" >
                            {{ item.cookingName }}
                        </div>
                    </div>
                </template>
            </div>
            <span slot="footer" class="modal-footer">
                <el-button @click="showModal = false">Cancel</el-button>
                <el-button type="primary" style="background-color: #aa0bc0;" @click="addSkuToCart()" 
                    :loading="foodDetails.itemId == addCartLoading || loadingFood">
                    <span >Add to basket</span>
                </el-button>
            </span>
        </el-dialog>

        <!-- FreeItem 弹窗内容展示 -->
        <el-dialog
            :visible.sync="freeItemModal"
            :width="isMobile?'90%':'30%'"
            :before-close="handleClose">
            <div style="padding-right:20px;" class="free-item-modal">
                <div class="modal-img">
                    <img class="alleat-icon" src="https://nweb.alleatapp.com/food/img/20230630/3132D06D81338CD68E254687658C5D0C.png" mode="widthFix" />
                </div>
                <div v-for="foodInfo in freeItemList" :key="foodInfo.itemId" >
                    <div class="modal-desc modal-desc-bold">
                        Nice! You get this free with your order
                    </div>
                    <div class="modal-desc">
                        We'll apply the discount at checkout
                    </div>
                    <div class="modal-desc modal-desc-name" v-if="!foodInfo.photo">
                        "{{ foodInfo.itemName }}"
                    </div>
                    <template v-else>
                        <div class="modal-desc modal-desc-bold modal-desc-name-normal">
                            "{{ foodInfo.itemName }}"
                        </div>
                        <div class="modal-food-img">
                            <img :src="foodInfo.photo" mode="aspectFill" />
                        </div>
                    </template>
                    <div class="modal-content" style="margin-top: 10px;">
                        <!-- <div class="price-title" >Specifications</div>
                        <ul>
                            <li v-for="(price,ind) in foodInfo.foodSkuPriceList" :key="ind" @click="nowChoosePrice(price, ind)"
                                :class="['price-item',{'price-item-active': nowPrice.sizeId == price.sizeId}]">
                                <span>{{ price.sizeName }}</span>
                                <span>{{ currencySign + price.foodPrice }}</span>
                            </li>
                        </ul> -->
                        <div class="dash-row" v-if="showAddonTitle"></div>
                        <div class="price-title" v-if="showAddonTitle">Additional items</div>
                        
                        <div class="food-loading" v-if="loadingFood">
                            <i class="el-icon-loading" ></i>
                        </div>
                        <template v-else>
                            <!-- 有价格 的 addon -->
                            <ul v-if="foodDetails.addOnInfoList && foodDetails.addOnInfoList.length">
                                <li v-for="(value, key) in foodDetails.addOnInfoList" :key="value.addonId">
                                    <div class="addon-title">
                                        {{ value.addonCatName}}
                                        ({{[0,1].includes(value.selectType)?'Single Choice':'Multiple Select'}}  {{value.required?'| '+ 'required':''}}) 
                                        <span v-if="value.selectType==3" >Max choose {{value.customSize}}</span>
                                    </div>
                                    <div class="addon-box" >
                                        <div :class="['price-item-addon',{'price-item-active':item.beingChoose}]" @click="chooseAddon(key,index)" :key="index" 
                                            v-for="(item,index) in value.subItemList" >
                                        {{ item.subItemName }} | {{currencySign}}{{item.addonPrice.toFixed(2)}}
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            <!-- 配菜 无价格 -->
                            <div class="addon-title" v-if="foodDetails.ingredientsList && foodDetails.ingredientsList.length>0">{{i18n.AddonItem}}</div>
                            <div class="addon-box" >
                                <div :class="['price-item-addon',{'price-item-active':nowChooseIdent.includes(item.ingredientsId)}]"
                                    v-for="(item) in foodDetails.ingredientsList"  :key="item.ingredientsId" @click="chooseIdent(item)" >
                                    {{ item.ingredientsName }}
                                </div>
                            </div>
                            
                            <!-- 口味 烹饪参考 -->
                            <div class="addon-title" v-if="foodDetails.cookingRefList && foodDetails.cookingRefList.length>0">{{i18n.Cuise}}</div>
                            <div class="addon-box" >
                                <div :class="['price-item-addon',{'price-item-active':nowCookingRef.includes(item.cookId)}]"
                                    v-for="(item) in foodDetails.cookingRefList" :key="item.cookId" @click="chooseCookingRef(item)" >
                                    {{ item.cookingName }}
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
            <span slot="footer" class="modal-footer">
                <el-button type="primary" style="background-color: #aa0bc0;width:100%" @click="addFreeItemIntoCard()" 
                    :loading="foodDetails.itemId == addCartLoading || loadingFood" >
                    <span>Add to basket</span>
                </el-button>
            </span>
        </el-dialog>

    </div>
</template>
<script>
import { postGateway } from '@/request';
export default {
    model: {
        prop: 'showSearchCover',
        handle: 'input'
    },
    props: {
        info: {
            type: Object,
            default: () => {
                return {
                    deliveryDistance: ''
                }
            }
        },
        currencySign: {
            type: String,
            default: () => ''
        },
        categoryList: {
            type: Array,
            default: () => []
        },
        foodList: {
            type: Array,
            default: () => []
        },
        cartList: {
            type: Array,
            default: () => []
        },
        MaxShopPrice: {
            type: Number,
            default: () => 99999
        },
        showSearchCover: {
            type: Boolean,
            default: false,
        }
    },
    computed: {
        showAddonTitle() {
            let { addOnInfoList, ingredientsList, cookingRefList } = this.foodDetails;
            return addOnInfoList.length || ingredientsList.length || cookingRefList.length
        },
        isMobile() {
            return window.innerWidth < 950
        },
        deliveryDistance() {
            let result = this.info.deliveryDistance;
            if(!result.includes('miles')) {
                result = result.replace('mile', 'miles')
            }
            return result.includes('null')? '' : result
        }
    },
    data() {
        return {
            clientId:  localStorage.getItem('clientId'),
            searchResult: [],
            searchLoading: true,
            nowChooseFood: {
                itemName: '',
                foodSkuPriceList: [],
                isAddon: 0,  // 是否有附加物品
            },
            nowPrice: {
                foodPrice: '',
                sizeId: ''
            },
            showModal: false,
            foodDetails: {
                addOnInfoList: [],
                ingredientsList: [],
                cookingRefList: [],
                itemId: null
            },
            nowChooseIdent: [],
            nowCookingRef: [],
            loadingFood: false,
            nowChoose: 0,
            addCartLoading: null,
            freeItemModal: false,
            freeItemList: [], // freeItem 内容
        }
    },
    mounted() {
        
    },
    methods: {
        // 设置 freeItem 并且弹出弹窗
        setFreeItemModal(list) {
            this.freeItemList = list;
            this.freeItemModal = true;
            let item = list[0]
            this.nowChooseFood = item;
            this.foodDetails.itemId = item.itemId;
            this.getFoodDetails(item)
        },
        // 添加FreeItem 进入购物车
        addFreeItemIntoCard() {
            if(this.addCartLoading || this.loadingFood) {
                return
            }
			
            this.clientId = localStorage.getItem('clientId');

            let item = this.nowChooseFood;
            if(this.nowChooseFood.isAddon || item.foodSkuPriceList.length>1) {
                item = this.foodDetails;
            }

            // 校验 addon 是否合法
            if(!this.judgeAddonCanAdd(this.nowChooseFood) && this.nowChooseFood.isAddon) {
                this.$message.info('Please choose the Additional items')
                return
            }

			let param = {
				categoryId: item.catId,
				qty: 1,  // 新增传1
				price: 0,
                chooseItems: item.foodSkuPriceList[this.nowChoose].sizeName,
                itemName: item.itemName,
				itemId: item.itemId,
				merchantId: this.info.merchantId,
                clientId: this.clientId,
				foodSkuId: item.foodSkuPriceList[this.nowChoose].foodSkuId,
				cookingRef: [],	// 烹饪参考
				ingredients: [], // 配菜
				subItems: [],// 附加物品
			}
			
			// 处理附加物品
			if(item.addOnInfoList) {
				item.addOnInfoList.forEach((cate)=>{
					cate.subItemList.forEach((addon)=>{
						if(addon.beingChoose) {  // 被选中
                            console.log(addon,'addon')
							param.subItems.push({
								"addonQty": 1,
								"subCatId": addon.addonCatId,  // 父级id
								"subItemId": addon.subItemId,  // 物品id
                                "subItemName": addon.subItemName
							})
						}
					})
				})
			}
			
			// 处理烹饪类型 口味
			param.cookingRef = this.nowChooseIdent;
			
			// 处理 配菜
			param.ingredients = this.nowCookingRef;

            // offId 只有 freeItem 有这个字段
            param.offId = this.nowChooseFood.offId;
            param.overFlag = this.nowChooseFood.overFlag;
            param.freeitemFlag = 1;
            param.offAmountLimit = this.nowChooseFood.offAmountLimit;  // 标记当前的freeItem 加入购物车的限制金额

            this.checkToCart(param).then(()=>{
                this.$emit('addCart', param, 'sku')
                param.qty = 1; // 新增赋值为1
                this.freeItemModal = false;
                this.addCartLoading = null;
                this.handleClose();
            })
            .catch((err)=>{
                console.log(err,'err')
                this.addCartLoading = null;
                if(err.code != 50034) {
                    this.$message.error(err.msg)
                }
            })
			
			console.log(param,'加入购物车回参')
        },
        // 校验是否能加入购物车
        checkToCart(param) {
            this.addCartLoading = param.itemId;
            let ids = this.cartList.map(x=>x.itemId)
            let checkParam = {
                ...param
            }
            if(ids.includes(param.itemId)) {
                checkParam.qty++;
            }
            return postGateway({
                url: '/customerApp/cart/checkAddAppCart',
                data: {
                    ...checkParam,
                    merchantId: this.info.merchantId
                }
            })
        },
        getTotalPrice() {  // 计算弹窗情况的价格
			let price = 0;
			if(this.foodDetails.addOnInfoList && this.foodDetails.addOnInfoList.length > 0) {
				price = this.foodDetails.foodSkuPriceList?this.foodDetails.foodSkuPriceList[this.nowChoose].foodPrice:0;
				this.foodDetails.addOnInfoList.forEach((cate)=>{
					cate.subItemList.forEach((item)=>{
						if(item.beingChoose) {
							price = price *1 + item.addonPrice*1;
						}
					})
				})
			} else {
				price = this.foodDetails.foodSkuPriceList?this.foodDetails.foodSkuPriceList[this.nowChoose].foodPrice:0
			}
			return price.toFixed(2);
		},
        // 判断 addon 是否合法
        judgeAddonCanAdd() {
            let judge = true;

            this.foodDetails.addOnInfoList.forEach((ele,ind1)=>{
                let time = 0;
                if(ele.selectType == 3 && ele.required == 1 && this.foodDetails.addOnInfoList[ind1].customSize*1 > 1) { // 多选 并且 必选
                    ele.subItemList.forEach(subItem=>{
                        if(subItem.beingChoose) {
                            time++;
                        }
                    })
                    if(time !=  this.foodDetails.addOnInfoList[ind1].customSize*1) {
                        judge = false;
                    }
                }
            })

            return judge
        },
        // sku 加入购物车
        addSkuToCart() {
            if(this.addCartLoading) {
                return
            }
			// if((item.foodSkuPriceList[this.nowChoose].foodPrice *1 + this.nowTotalPrice*1) > this.MaxShopPrice) {
			// 	this.$message.info('Over max shop price')
			// 	return
			// }
            this.clientId = localStorage.getItem('clientId');

            let item = this.nowChooseFood;
            if(this.nowChooseFood.isAddon || item.foodSkuPriceList.length>1) {
                item = this.foodDetails;
            }

            // 校验 addon 是否合法
            if(!this.judgeAddonCanAdd(this.nowChooseFood) && this.nowChooseFood.isAddon) {
                this.$message.info('Please choose the Additional items')
                return
            }

			let param = {
				categoryId: item.catId,
				qty: 1,  // 新增传1
				price: this.getTotalPrice() || item.foodSkuPriceList[this.nowChoose].foodPrice,
                chooseItems: item.foodSkuPriceList[this.nowChoose].sizeName,
                itemName: item.itemName,
				itemId: item.itemId,
				merchantId: this.info.merchantId,
                clientId: this.clientId,
				foodSkuId: item.foodSkuPriceList[this.nowChoose].foodSkuId,
				cookingRef: [],	// 烹饪参考
				ingredients: [], // 配菜
				subItems: [],// 附加物品
			}
			
			// 处理附加物品
			if(item.addOnInfoList) {
				item.addOnInfoList.forEach((cate)=>{
					cate.subItemList.forEach((addon)=>{
						if(addon.beingChoose) {  // 被选中
                            console.log(addon,'addon')
							param.subItems.push({
								"addonQty": 1,
								"subCatId": addon.addonCatId,  // 父级id
								"subItemId": addon.subItemId,  // 物品id
                                "subItemName": addon.subItemName
							})
						}
					})
				})
			}
			
			// 处理烹饪类型 口味
			param.cookingRef = this.nowChooseIdent;
			
			// 处理 配菜
			param.ingredients = this.nowCookingRef;
			
			console.log(param,'加入购物车回参')
			// return

            this.checkToCart(param).then(()=>{
                this.$emit('addCart', param, 'sku')
                param.qty = 1; // 新增赋值为1
                this.showModal = false;
                this.addCartLoading = null;
                this.handleClose();
            })
            .catch((err)=>{
                console.log(err,'err')
                this.addCartLoading = null;
                if(err.code != 50034) {
                    this.$message.error(err.msg)
                }
            })
        },
        // 选择附加物品
		chooseAddon(ind1,ind2) {		
			if(!this.foodDetails.addOnInfoList[ind1].subItemList[ind2].beingChoose && [0,1].includes(this.foodDetails.addOnInfoList[ind1].selectType)) {  // 单选物品
                this.foodDetails.addOnInfoList[ind1].subItemList.forEach((ele,ind)=>{
					this.foodDetails.addOnInfoList[ind1].subItemList[ind].beingChoose = false;
				})
				this.foodDetails.addOnInfoList[ind1].subItemList[ind2].beingChoose = true;
				return
			}				
			// 判断必选 & 单选 判断取消选择 只剩一个 不允许执行
			if( this.foodDetails.addOnInfoList[ind1].required == 1 && [0,1].includes(this.foodDetails.addOnInfoList[ind1].selectType) && this.foodDetails.addOnInfoList[ind1].subItemList[ind2].beingChoose) {
				let judge = false;
				this.foodDetails.addOnInfoList[ind1].subItemList.forEach((ele,ind)=>{
					if(this.foodDetails.addOnInfoList[ind1].subItemList[ind].beingChoose && ind !== ind2) {
						judge = true;
					}
				})
				if(!judge) {
					return
				}
			}
			
			// 判断多选  最多能选几个 customSize  selectType == 3
			let times = 0;
			if(this.foodDetails.addOnInfoList[ind1].selectType == 3 && !this.foodDetails.addOnInfoList[ind1].subItemList[ind2].beingChoose) {
				if(this.foodDetails.addOnInfoList[ind1].customSize*1 == 1) {
					// 自定义多选 只能选1个 但是 是必选处理
					this.foodDetails.addOnInfoList[ind1].subItemList.forEach((ele,ind)=>{
						this.foodDetails.addOnInfoList[ind1].subItemList[ind].beingChoose = false;
					})
					this.foodDetails.addOnInfoList[ind1].subItemList[ind2].beingChoose = !this.foodDetails.addOnInfoList[ind1].subItemList[ind2].beingChoose;
					return
				}
				
				this.foodDetails.addOnInfoList[ind1].subItemList.forEach((ele,ind)=>{
					if(this.foodDetails.addOnInfoList[ind1].subItemList[ind].beingChoose) {
						times ++;
					}
				})
				if(times >= this.foodDetails.addOnInfoList[ind1].customSize*1) {
					return
				}
			}
			this.foodDetails.addOnInfoList[ind1].subItemList[ind2].beingChoose = !this.foodDetails.addOnInfoList[ind1].subItemList[ind2].beingChoose;
		},
        // 选择配菜
		chooseIdent(item) {
			if(!this.nowChooseIdent.includes(item.ingredientsId)) {
				this.nowChooseIdent.push(item.ingredientsId);
			} else {
				this.nowChooseIdent.splice(this.nowChooseIdent.indexOf(item.ingredientsId),1)
			}
		},
		// 选择口味 、 烹饪参考 单选
		chooseCookingRef(item) {
			this.nowCookingRef = [item.cookId];
		},
        getFoodDetails(item) { // 获取食品详情
            this.nowChooseIdent = [];
			this.nowCookingRef = [];
            this.loadingFood = true;
            postGateway({
                url: '/customerApp/merchantDetail/getFoodDetail',
                method: 'GET',
                data: {
                    itemId: this.nowChooseFood.itemId
                }
            }).then(res=>{
                let obj = res.data;
				obj.cookingRefList = res.data.cookingRefList?res.data.cookingRefList:[];
				if(obj.cookingRefList.length > 0) {
					this.nowCookingRef = [obj.cookingRefList[0].cookId];
				}
				obj.catId = item.catId;
				res.data.addOnInfoList.forEach((cate,catInd)=>{
					cate.subItemList.forEach((item,ind)=>{
						if(ind == 0 && cate.required == 1) {
							obj.addOnInfoList[catInd].subItemList[ind].beingChoose = true;
						} else {
							obj.addOnInfoList[catInd].subItemList[ind].beingChoose = false;
						}
						
					})
				})
				this.foodDetails = obj;
                this.loadingFood = false;
                this.nowChoose = 0;
            }).catch(()=>{
                this.loadingFood = false;
            })
        },
        // 选择规格 同步个数
		chooseSkuPrice(item,index) {
			this.nowChoose = index;
            console.log(item, index)
		},
        nowChoosePrice(item, ind) {
            this.nowPrice = item;
            this.nowChoose = ind;
        },
        addCart(food, isAdd=false) {
            console.log(food,'当前food')
            // 多价格不能直接加入购物车
            if((food.foodSkuPriceList && food.foodSkuPriceList.length>1) || food.isAddon) {
                this.nowChooseFood = food;
                this.nowPrice = food.foodSkuPriceList[0]
                this.showModal = true;

                let keyArr = ['addOnInfoList', 'ingredientsList', 'cookingRefList'];
                keyArr.forEach(ele=>{
                    this.foodDetails[ele] = []
                })

                if(food.isAddon) {
                    this.getFoodDetails(food)
                } else {
                    this.loadingFood = false;
                    this.foodDetails = {
                        addOnInfoList: [],
                        ingredientsList: [],
                        cookingRefList: [],
                        ...food
                    };
                    console.log('执行了这里去掉loading')
                }
                return
            }
            let item = food;

            // 判断购价格是否过多了
			// if((item.foodSkuPriceList[0].foodPrice *1 + this.nowTotalPrice*1) > this.MaxShopPrice) {
			// 	this.$message.info('Over max shop price')
			// 	return
			// }

            isAdd ? item.qty++ : item.qty--;
            // 一口价的菜品直接加入购物车
			let param = {
				clientId: this.clientId,
				categoryId: item.catId,
				qty: item.qty,  // 新增传1
				price: item.foodSkuPriceList[0].foodPrice,
				itemId: item.itemId,
                itemName: item.itemName,
				merchantId: this.info.merchantId,
				foodSkuId: item.foodSkuPriceList[0].foodSkuId,
				cookingRef: [],	// 待定
				ingredients: [],// 待定
				subItems: [],// 待定
			}

            if(param.qty > 0) {
                this.checkToCart(param).then(()=>{
                    this.$emit('addCart', param, 'single')
                    this.addCartLoading = null;
                })
                .catch((err)=>{
                    console.log(err,'err')
                    if(err.code !== 50032) {
                        this.$message.error(err.msg)
                    }
                    this.addCartLoading = null;
                    // 加入失败 复原数量
                    isAdd ? item.qty-- : item.qty++;
                })
            } else {
                this.$emit('addCart', param, 'single')
            }
        },
        handleClose() {
            this.showModal = false;
            this.freeItemModal = false;
            this.nowChooseIdent = [];
            this.nowCookingRef = [];
            this.foodDetails.addOnInfoList = []
            this.foodDetails.ingredientsList = []
            this.foodDetails.cookingRefList = []
        },
    }
}
</script>
<style lang="scss" scoped>
.menu-content {
    padding: 10px 20px 20px;
    min-height: 100vh;

    .distance-box {
        height: 40px;
        display: flex;
        align-items: flex-end;
        font-size: 15px;
        font-weight: 600;
        padding: 0 10px;

        i {
            font-size: 24px;
            margin-right: 5px;
            font-weight: bold;
            color: #000 !important;
        }
    }

    .menu-title {
        position: sticky;
        padding-top: 10px;
        background-color: #fff;
        top: 0px;

        h2 {
            font-weight: 700;
            color: #fff;
            font-size: 25px;
            padding: 10px 20px 10px;
            margin: 0;
            background-color: #09CA6A;
            border-radius: 6px;
            box-shadow: 0px 4px 6px #8F9698;
        }
    }

    .modal-title {
        text-align: center;
        font-weight: 600;
        color: #222;
        font-size: 18px;
        white-space: pre-wrap;
    }
    :deep(.el-dialog__body) {
        padding-right: 0;
        padding-bottom: 0;
    }
    .modal-content {
        margin-top: -20px;
        max-height: 60vh;
        overflow: auto;
        padding-right: 16px;
        margin-right: 4px;

        &::-webkit-scrollbar {
            width: 6px;
        }

        .price-title {
            color: #09CA6A;
            font-size: 16px;
            text-align: left;
            margin-bottom: 15px;
        }
        .dash-row {
            margin: 15px 0;
            border-top: 1px dashed #cacaca
        }
        .addon-title {
            color: #aa0bc0;
            margin-bottom: 15px;
            margin-top: 10px;
        }
        .price-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 15px;
            padding: 6px 10px;
            border-radius: 4px;
            border: 2px solid #f4f3f3;
            margin-bottom: 10px;
            color: #000;
            cursor: pointer;
            .old-price {
                text-decoration: line-through;
                color: #9d9e9e;
                padding-right: 2px;
                font-size: 12px;
            }
        }
        .price-item-active {
            color: #aa0bc0;
            border: 2px solid #aa0bc0;
            background-color: #f9eafc;
        }
        .addon-box {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            .price-item-addon {
                display: inline-flex;
                justify-content: flex-start;
                align-items: center;
                justify-content: space-between;
                font-size: 15px;
                padding: 6px 10px;
                border-radius: 4px;
                border: 2px solid #f4f3f3;
                margin-bottom: 10px;
                color: #000;
                cursor: pointer;
                margin-right: 10px;
            }
            .price-item-active {
                color: #aa0bc0;
                border: 2px solid #aa0bc0;
                background-color: #f9eafc;
            }
        }
    }

    .modal-img {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-bottom: 15px;
		margin-top: -30px;
		.alleat-icon {
			height: 55px;
			width: 55px;
		}
	}
	.modal-desc {
		font-weight: normal;
		font-size: 15px;
		margin-top: 10px;
		color: #000;
		text-align: center;
	}
	.modal-desc-bold {
		font-weight: bolder;
		font-size: 14px;
	}
	.modal-desc-name {
		font-weight: bold;
		font-size: 19px;
		margin-top: 18px;
		margin-bottom: 18px;
	}
	.modal-desc-name-normal {
		font-weight: bold;
		font-size: 15px;
	}
	.free-item-btn {
		height: 40px;
		width: 100%;
		border-radius: 20px;
		margin-top: 12px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: bold;
		background-color: #ab1ec4;
		color: #fff;
	}
	
	.modal-food-img {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 12px;
		img {
			height: 110px;
			width: 110px;
		}
	}

    .free-item-modal {
        .modal-content {
            padding-right: 0;
            max-height: 34vh;
        }
    }

    .search-box {
        background-color: #fff;
        margin-top: 20px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    }
    .cate-box {
        padding: 10px 20px 20px;
        border-radius: 8px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        margin-top: 20px;
        background-color: #fff;

        h2 {
            font-weight: bold;
            font-size: 24px;
            color: #000;
            margin: 0;
            white-space: pre-wrap;
            word-wrap: break-word;
        }

        .desc {
            font-size: 15px;
            color: #0A191E;
            padding: 10px 0 10px;
            word-break: normal;
            white-space: pre-wrap;
        }
    }
    .loading-box , .food-loading {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 40px 0 20px 0;
        .el-icon-loading {
            color: #AA0BC0;
            font-size: 25px;
        }
        .empty-img {
            width: 400px;
            border-radius: 12px;
        }
    }
    .food-item {
        background-color: #fff;
        display: flex;
        border-bottom: 0.5px solid #00000033;
        padding: 15px 0;
        align-items: center;
        font-family: "Source Sans Pro", sans-serif;

        &:hover {
            background-color: #f7f5f5;
        }

        .item-name {
            flex: 1;
            display: flex;
            word-break: normal;
            padding-left: 10px;
            overflow: hidden;
            white-space: normal;
            font-size: 12px;

            .food-img {
                height: 60px;
                width: 60px;
                min-width: 60px;
                border-radius: 6px;
                background-color: #e9e9e9;
                overflow: hidden;
                margin-right: 10px;
                object-fit: contain;
            }

            img {
                height: 60px;
                width: 60px;
            }

            .name {
                font-size: 15px;
                color: #333;
                font-weight: bold;
            }

            .name-desc {
                margin: 0 4px;
                font-size: 20px;
            }

            .desc {
                font-size: 14px;
                color: #8d8d8d;
                font-weight: 400;
            }
            .price {
                font-weight: bold;
                color: #000;
                margin-top: 8px;
                font-size: 19px;
                .origin-price {
                    text-decoration: line-through;
                    color: #999;
                    font-weight: 500;
                    font-size: 15px;
                }
            }
        }

        .item-price {
            padding-left: 10px;
            min-width: 60px;
            width: 20%;
            text-align: left;
            font-weight: 600;
        }

        .item-order {
            width: 20%;
            min-width: 100px;
            text-align: center;

            .add-btn {
                min-width: 90px;
                height: 32px;
                border: 2px solid #AA0BC0;
                border-radius: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 16px;
                color: #AA0BC0;
                font-weight: 700;
                padding: 0 14px;
                cursor: pointer;
                transition: all ease-in .2s;
                white-space: nowrap;

                i {
                    color: #AA0BC0;
                    margin-right: 2px;
                    font-weight: 700;
                    font-size: 12px;
                    margin-right: 4px;
                }

                .qty {
                    color: #000;
                }

                &:hover {
                    background-color: #AA0BC0;
                    border: 2px solid #fff;
                    color: #fff;

                    i {
                        color: #fff;
                    }
                }
            }
            .add-addon {
                background-color: #AA0BC0;
                color: #fff;
                font-weight: 500;
            }

            .el-icon-delete {
                margin-left: 2px;
            }
            .update-btn {
                display: flex;
                justify-content: space-between;
                border: 1px solid rgba(0, 0, 0, 0.25);
                i {
                    margin: 0;
                }
            }

        }
    }

    .food-header {
        font-weight: bold;
        color: #000;
        border-top: 0.5px solid #00000033;
        padding: 20px 0;
        background-color: #f7f5f5;

        .item-price {
            padding-left: 0;
            text-align: left;
            font-weight: 600;
            padding-left: 10px;
        }

        .item-order {
            padding-right: 20px;
        }
    }
}

@media screen and (max-width: 950px) {
    .menu-box .menu-content{
        padding: 10px 10px 20px;
        font-family: "Source Sans Pro", sans-serif;
        .distance-box {
            height: auto;
        }
        .menu-title h2{
            font-size: 18px;
        }
        .cate-box {
            background-color: #fff!important;
            h2 {
                font-size: 15px;
            }
            .food-box {
                .food-item {
                    font-family: "Source Sans Pro", sans-serif;
                    .index-num {
                        font-size: 12px;
                        color: #09a054;
                        font-weight: bold;
                        margin-right: 10px;
                    };
                    .item-name {
                        .name {
                            font-size: 15px;
                        }
                        .desc {
                            font-size: 12px;
                        }
                    }
                    .add-btn {
                        font-size: 13px;
                    }
                }
            }
        }
        .modal-title {
            word-break: normal;
            overflow: hidden;
            font-size: 14px;
            font-weight: bold;
        }
        .modal-content {
            .price-title {
                font-weight: bold;
                font-size: 14px;
            }
            ul .price-item {
                font-size: 12px;
            }
            .addon-title {
                font-size: 13px;
            }
            .addon-box {
               .price-item-addon {
                font-size: 12px;
               }
            }
        }
    }
}

</style>