<template>
  <div :class="['fix-header', { 'no-fixed': noFixedHeader }]">
    <header>
      <div class="logo-Box">
        <!-- <img
          class="logo-img"
          src="@assets/logo-merchant.png"
          alt="logo"
        /> -->
        <h2>{{ MerchantConfig.merchantName }}</h2>
        <span class="point" >.</span>
        <a href="/"></a>
      </div>

      <span style="flex: 1"></span>
      <a :class="['header-btn', { 'handle-btn-active': nowNav=='home' }]" href="/"  @click="closePop">Home</a>
      <a :class="['header-btn', { 'handle-btn-active': nowNav=='about' }]" href="/about.html"  @click="closePop">About Us</a>
      <a :class="['header-btn', { 'handle-btn-active': nowNav=='login' }]" href="/login" v-if="!token">
        Login | Register
      </a>
      <a :class="['header-btn', { 'handle-btn-active': nowNav=='usercenter' }]" href="/usercenter"  @click="closePop" v-else>
        User Center
      </a>
      <a :class="['header-btn', { 'handle-btn-active': nowNav=='orders' }]" href="/usercenter/orderhistory" @click="closePop" v-if="token">
        My Orders
      </a>
      <a :class="['header-btn', { 'handle-btn-active': nowNav=='faqs' }]" href="/faqs" @click="closePop">
        FAQ's
      </a>
      <a :class="['header-btn', { 'handle-btn-active': nowNav=='faqs' }]" href="/contact.html" @click="closePop">
        Contact Us
      </a>
      <el-popover
        placement="bottom"
        width="180"
        v-model="showPop"
        trigger="manual"
      >
        <ul class="menu-box">
          <li>
            <a :class="[{ 'handle-btn-active': nowNav=='home' }]" href="/"  @click="closePop">Home</a>
          </li>
          <li>
            <a :class="[ { 'handle-btn-active': nowNav=='about' }]" href="/about.html" @click="closePop">About Us</a>
          </li>
          <li v-if="!token">
            <a :class="[ { 'handle-btn-active': nowNav=='login' }]" href="/login" >
              Login | Register
            </a>
          </li>
          <li  v-else>
            <a :class="[ { 'handle-btn-active': nowNav=='usercenter' }]" href="/usercenter"  @click="closePop">
              User Center
            </a>
          </li>
          <li  v-if="token">
            <a :class="[ { 'handle-btn-active': nowNav=='order' }]" href="/usercenter/orderhistory" @click="closePop">
              My Orders
            </a>
          </li>
          <!-- <li class="btn-green" @click="becomePart">Become Partner</li> -->
          <!-- <li style="position: relative">
            EN
            <i class="el-icon-arrow-down"></i>
          </li> -->
          <li @click="contact">
            <a :class="[ { 'handle-btn-active': nowNav=='order' }]" href="/contact.html" @click="closePop" >
              Contact Us
            </a>
          </li>
        </ul>

        <div slot="reference">
          <span class="menu-icon" @click="showPop=true" @touchstart="showPop=true">
            <i class="el-icon-s-unfold"></i>
          </span>
        </div>
      </el-popover>
    </header>

    <div class="cover-box" v-if="showPop" @touchstart="showPop = false" ></div>
  </div>
</template>
<script>
import bus from "@/utils/bus.js";
const MerchantConfig = require("../../merchant.config.js");
export default {
  name: "Header",
  props: {
    noFixedHeader: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      MerchantConfig,
      token: "",
      showPop: false,
    };
  },
  mounted() {
    this.token = localStorage.getItem("token");
    bus.$on('login', () => {
      this.token = localStorage.getItem("token");
    });
    bus.$on('logout', () => {
      this.token = localStorage.getItem("token");
    });
    // window.onscroll = function(e) {
    //   console.log(e, '滚动了')
    // }
  },
  computed: {
    nowNav() {
      let url = location.pathname.replace('/');
      let pathName = 'home';
      if(url.includes('about')) {
        pathName = 'about'
      }
      if(url.includes('usercenter')) {
        pathName = 'usercenter'
      }
      if(url.includes('orderhistory')) {
        pathName = 'order'
      }
      if(url.includes('faqs')) {
        pathName = 'faqs'
      }
      if(url.includes('login')) {
        pathName = 'login'
      }
      
      return pathName
    }
  },
  methods: {
    closePop() {
      this.showPop = false;
      console.log( this.showPop,"调用了")
    },
    contact() {
      document.location.href = `${this.MerchantConfig.merchantDomain}/contact.html`
    },
    goHome() {
      // if(localStorage.getItem('searchAddress')) {
      //   this.$router.push({
      //     path: '/browse'
      //   })
      // } else {
      //   this.$router.replace({
      //     path: "/",
      //   });
      // }
      document.location.href = `${this.MerchantConfig.merchantDomain}/index.html`
      this.closePop();
    },
    goUserPage() {
      this.$router.push({
        path: "/usercenter",
      });
      this.closePop();
    },
    goOrderPage() {
      this.$router.push({
        path: "/usercenter/orderhistory",
      });
      this.closePop();
    },
    goLogin() {
      this.$router.push({
        path: "/login",
        query: {
          a: 10,
        },
      });
      this.closePop();
    },
    aboutUs() {
      document.location.href = `${this.MerchantConfig.merchantDomain}/about.html`
      
      this.closePop();
    },
    goFaqs() {
      this.$router.push({
        path: "/faqs",
      });
      this.closePop();
    },
    becomePart() {
      window.open("https://restaurants.alleatapp.com");
      // this.$router.push({
      //   path: "/contact",
      // });
      this.closePop();
    },
  },
};
</script>

<style lang="scss" scoped>
.fix-header {
  height: 93px;
}
.no-fixed {
  header {
    position: relative !important;
  }
}
.cover-box {
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  height: 200vh;
  width: 100%;
  background: rgba(0, 0, 0, 0.35);
}
header {
  padding: 0 4%;
  display: flex;
  align-items: center;
  height: 96px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  position: fixed;
  background-color: #fff;
  top: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  z-index: 100;

  .logo-Box {
    display: flex;
    align-items: baseline;
    position: relative;
    
    h2 {
      font-family: 'Inter', sans-serif;
      font-weight: bold;
      font-size: 28px;
      font-weight: 700;
      color: #000;
    }
    .point {
      color: #02CA69;
      font-weight: bold;
      font-size: 28px;
    }
    a {
      position: absolute;
      height: 100%;
      width: 100%;
      z-index: 20;
    }
  }
  .logo-img {
    width: 180px;
    height: 60px;
    margin-right: 20px;
  }

  .logo-font {
    font-size: 30px;
    font-weight: bold;
    color: #aa0bc0;
  }

  .eat {
    margin-left: 8px;
    color: #aa0bc0;
    color: #26d07c;
  }

  .header-btn {
    margin-left: 16px;
    background: #fff;
    height: 46px;
    padding: 0 20px;
    border-radius: 5px;
    display: inline-flex;
    line-height: 40px;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 15px;
    color: #000;
    cursor: pointer;
    transition: all 0.3s ease-in-out 0s;
    white-space: nowrap;
    position: relative;
    i {
      font-size: 20px;
      margin-right: 10px;
    }

    .el-icon-arrow-down {
      margin-left: 10px;
      font-weight: 700;
      color: #000;
      margin-right: 0;
    }

    &.handle-btn-active::after {
      content: '';
      position: absolute;
      bottom: 6px;
      left: 50%;
      transform: translateX(-50%);
      height: 3px;
      width: 70%;
      border-radius: 4px;
      background-color: #43d68d;
    }
  }

  .menu-icon {
    display: none;
  }

  .btn-green {
    background: #09ca6a;
    color: #fff;
  }
}

.menu-box {
  display: flex;
  flex-direction: column;

  li {
    background: #fff;
    // box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    height: 46px;
    padding: 0 20px;
    border-radius: 5px;
    display: inline-flex;
    line-height: 40px;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 14px;
    color: #222;
    cursor: pointer;
    transition: all 0.3s ease-in-out 0s;
    margin-top: 12px;
    white-space: nowrap;
    i {
      font-size: 20px;
      margin-right: 10px;
    }

    .el-icon-arrow-down {
      margin-left: 10px;
      font-weight: 700;
      color: #000;
      margin-right: 0;
    }
    a {
      position: relative;
      &.handle-btn-active::after {
        content: '';
        position: absolute;
        bottom: 6px;
        left: 50%;
        transform: translateX(-50%);
        height: 3px;
        width: 100%;
        border-radius: 4px;
        background-color: #43d68d;
      }
    }
  }
  .btn-green {
    background: #09ca6a;
    color: #fff;
  }
}

// 处理兼容
@media screen and (max-width: 950px) {
  .fix-header {
    height: 65px;
  }
  .no-fixed {
    header {
      // position: fixed!important;
    }
  }
  header {
    height: 65px;
    .logo-img {
      width: 112px;
      height: 39px;
      margin-right: 15px;
    }

    .logo-font {
      font-size: 20px;
    }

    .eat {
      margin-left: 4px;
    }
    .header-btn {
      display: none;
    }
    .menu-icon {
      display: inline-flex;
      font-size: 26px;
      color: #000;
    }
  }
}
</style>
