<template>
  <div>
    <div class="form-box">
      <div class="top-box">
        <a @click="preTab" class="back" v-if="currentTab > 0 && currentTab!=2">
          <i class="el-icon-back"></i>
        </a>
        <!-- <a @click="nextTab(null)" class="back" style="margin-left: 10px;" v-if="currentTab < 4">
          <i class="el-icon-right"></i>
        </a> -->
        <span style="flex: 1"></span>
        <a @click="goBack" class="close">
          <i class="el-icon-close"></i>
        </a>
      </div>
      <div class="slide-box">
        <div>
          <div :key="0" style="width: 100%; padding: 0 20px" v-if="currentTab==0">
            <div>
              <h2>Sign in with mobile number</h2>
              <p>
                Email users can still login using their number
              </p>
              <div class="input-box">
                <div class="area-box">
                  <PhoneBtn v-model="form.code"></PhoneBtn>
                </div>
                <div class="shadow-box" >
                  <input
                    class="input"
                    v-model="form.phone"
                    @keyup="form.phone=form.phone.replace(/\D/g,'')"
                    name=""
                    placeholder="Your phone number"
                    type="text"
                    autofocus="autofocus"
                    @keydown.enter="showCaptcha"
                  />
                </div>
              </div>
              <div :class="['check-box',{'shaking': showAgreeAnimation }]" >
                <el-checkbox size="medium" v-model="agree"></el-checkbox>
                <span @click="agree = !agree">Agree</span>
                <p>
                  By submitting your number, you agree to All Eat App's 
                  <a href="/termsConditions" class="under-line">Terms & Conditions, </a>
                  <a href="/faqs" class="under-line">Privacy Policy , and Cookies Policy.</a>
                </p>
              </div>
              <div
                :class="[
                  'sign-btn',
                  'flex-row-center',
                  { 'disable-btn': !canGetCode },
                ]"
                @click="showCaptcha"
              >
                {{ sendPhonecode }}
              </div>
              <div class="flex-row-center being-send" @click="gotCode">
                I've got the code
              </div>
            </div>
          </div>

          <!-- 填写验证码 登录 -->
          <div :key="1" v-if="currentTab==1">
            <h2>Enter your code</h2>
            <p>
              We've sent your 4-digit code to <br />
              <span class="under-line">{{ form.code }} {{ form.phone }}</span>
            </p>
            <div class="ver-code">
              <input
                v-if="currentTab==1"
                v-model="form.verCode"
                name=""
                type="digital"
                @focus="inputFocus = true"
                @blur="inputFocus = false"
                @keyup="form.verCode = form.verCode.substring(0, 4)"
                autofocus="autofocus"
                placeholder="Your Code"
              />
              <div class="mask-box">
                <span class="font-box" v-for="(item, ind) in 4" :key="ind">
                  {{ form.verCode[ind] }}
                  <span
                    class="ol-line"
                    v-if="ind == form.verCode.length"
                  ></span>
                </span>
              </div>
            </div>
            <div :class="['resend-btn',{'disabled':!canGetCode}]" @click="showCaptcha(true)" >{{ sendPhonecode }}</div>
            <div
              style="width: 400px; margin: 20px auto 0"
              :class="[
                'sign-btn',
                'flex-row-center',
                'confirm-btn',
                { 'disable-btn': !cantLogin },
              ]"
              @click="login"
            >
              Confirm
            </div>
          </div>

          <!-- 补充姓名邮箱 -->
          <div :key="2" v-if="currentTab==2">
            <h2>
              Enter your <br />
              Name & Email
            </h2>
            <p class="name-tips">
              Please enter email to get <br />
              order confirmation and offers
            </p>
            <div class="form-line">
              <input type="text" name="" v-model="form.nickName" placeholder="Name" v-if="currentTab==2">
            </div>
            <div class="form-line">
              <input type="text" name="" v-model="form.email" placeholder="Email" v-if="currentTab==2">
            </div>
            <div
              style="width: 400px; margin: 20px auto 0;border-radius: 29px;"
              :class="[
                'sign-btn',
                'flex-row-center',
              ]"
              @click="submitNameInfo"
            >
              Submit
            </div>
          </div>

          <!-- 搜索地址 -->
          <div :key="3" v-if="currentTab==3">
            <h2 v-if="$isUk">
              Searching <br />
              Post code
            </h2>
            <h2 v-else>
              Searching <br />
              Irecode
            </h2>
            <div class="swtich-box" v-if="$isUk" >
              <el-tooltip class="item" effect="dark" content="Can't find your address? Try to open this mode. your address try open this mode" placement="top-start">
                <span>
                  Open Google Mode
                  <i class="el-icon-warning" ></i>
                </span>
              </el-tooltip>
              
              <el-switch
                v-model="useGoogleSearch"
                active-text=""
                inactive-text=""
                active-color="#aa0cc0"
                >
              </el-switch>
            </div>
            <div class="form-address-line">
              <div class="input-address-box" >
                <i class="el-icon-location "></i>
                <input type="text"  v-model="keywords" :placeholder="`Enter Your ${$isUk?'Post code':'Irecode'}`" name=""
                  v-if="currentTab==3"
                  @input="inputNow"
                  @keyup="inputNow"
                  @focus="focusNow = true"
                  @blur="focusNow = false"
                >
              </div>
              <div class="search-btn" >
                <img src="@/assets/images/search.png" class="el-icon-search" />
                <span class="text">Find Address</span>
              </div>
              <!-- 搜索结果内容 -->
              <div :class="['expand-box', { 'expand-box-active': (keywords && focusNow || resultList.length) }]">
                <div
                  class="search-item"
                  v-for="(item, ind) in resultList"
                  :key="ind"
                  @click="normalSearch(item)"
                >
                  <span style="font-size: 16px;">{{ item.content }}</span>
                  <i class="el-icon-loading" v-if="itemLoading == item.place_id && item.type=='location'"></i>
                  <img v-else src="@/assets/images/right.png" alt="right-arrow" />
                </div>
                <div class="load-box" v-if="loading">
                  <span class="loader"></span>
                </div>
                <div class="load-box" style="height: 50%;" v-else-if="!loading && !resultList.length">
                  No data.
                  <div class="empty-btn" > Can't find your address? Try to open this mode. your location? <br /> Try to type street name </div>
                </div>
                
              </div>
            </div>
          </div>

          <!-- 添加地址 -->
          <div :key="4" v-if="currentTab==4">
            <h2>
              Enter your Delivery Details
            </h2>
            <template v-if="this.$isUk">
              <h3 :class="['edit-form-title',{'empty-emptyTipsShaing-animate': showEmpty && !addressSearchForm.formattedAddress}]" >
                Addres
              </h3>
              <div class="edit-form-line">
                <input type="text" name="" v-if="currentTab==4" v-model="addressSearchForm.formattedAddress" placeholder="Apartment, London Road ">
              </div>
              <h3 :class="['edit-form-title',{'empty-emptyTipsShaing-animate': showEmpty && !addressSearchForm.doorNumber && !saveAddressForm.dontDoorNum }]" >Door Number</h3>
              <div class="edit-form-line">
                <input type="text" name="" v-if="currentTab==4" v-model="saveAddressForm.doorNumber" :disabled="saveAddressForm.dontDoorNum" placeholder="Flat/Door/House Number">
              </div>

              <div class="normal-form-line" v-if="!isMobile">
                <el-checkbox style="margin-left:5px;" size="medium" v-model="saveAddressForm.dontDoorNum"></el-checkbox>
                <span class="check-tips" style="cursor:pointer;" @click="saveAddressForm.dontDoorNum=!saveAddressForm.dontDoorNum">I Don’t Have A Door Number</span>

                <span style="flex: 1;" ></span>
                <span :class="['check-tips',{'empty-emptyTipsShaing-animate': showEmpty && !addressSearchForm.zipcode}]" style="margin-right: 12px;">Postcode</span>
                <span class="shadow-box" >
                  <input type="text" name="" v-if="currentTab==4" v-model="addressSearchForm.zipcode" placeholder="Postcode" />
                </span>
              </div>

              <div class="normal-form-line" v-if="isMobile">
                <el-checkbox style="margin-left:5px;" size="medium" v-model="saveAddressForm.dontDoorNum"></el-checkbox>
                <span class="check-tips" style="cursor:pointer;" @click="saveAddressForm.dontDoorNum=!saveAddressForm.dontDoorNum">I Don’t Have A Door Number</span>
              </div>
              <div class="normal-form-line" v-if="isMobile">
                <span class="check-tips" style="margin-right: 12px;margin-left: 0;">Postcode</span>
                <span class="shadow-box" >
                  <input type="text" name="" v-if="currentTab==4" v-model="addressSearchForm.zipcode" placeholder="Postcode" />
                </span>
              </div>
              
              <div class="normal-form-line" style="height: 65px;margin: 0px auto 20px;" >
                <div style="flex:1;min-width: 42%;">
                  <h3 :class="['edit-form-title',{'empty-emptyTipsShaing-animate': showEmpty && !addressSearchForm.city }]" style="width: 100%;">City</h3>
                  <div class="edit-form-line" style="width: 100%;">
                    <input type="text" name="" v-if="currentTab==4" v-model="addressSearchForm.city" placeholder="Name">
                  </div>
                </div>
                <span style="flex:1;" ></span>
                <div style="flex:1;min-width: 42%;">
                  <h3 :class="['edit-form-title',{'empty-emptyTipsShaing-animate': showEmpty  && !addressSearchForm.country }]" >Country</h3>
                  <div class="edit-form-line">
                    <el-select v-model="addressSearchForm.country" placeholder="Country" style="width: 100%;">
                      <el-option
                        v-for="item in countryList"
                        :key="item.code"
                        :label="item.text"
                        :value="item.code">
                      </el-option>
                    </el-select>
                  </div>
                </div>
              </div>

              <h3 :class="['edit-form-title',{'empty-emptyTipsShaing-animate': showEmpty && !form.nickName }]" >Full Name</h3>
              <div class="edit-form-line">
                <input type="text" name="" v-if="currentTab==4" v-model="form.nickName" placeholder="Name">
              </div>
              <h3 :class="['edit-form-title',{'empty-emptyTipsShaing-animate': showEmpty && !form.email }]" >Email</h3>
              <div class="edit-form-line">
                <input type="text" name="" v-if="currentTab==4" v-model="form.email" placeholder="Email">
              </div>

              <h3 :class="['edit-form-title',{'empty-emptyTipsShaing-animate': showEmpty  && !form.phone }]" >Phone Number</h3>
              <div class="normal-form-line">
                <div class="area-box">
                  <PhoneBtn v-model="form.code"></PhoneBtn>
                </div>
                <div class="shadow-box" >
                  <input type="text" name="" v-if="currentTab==4" style="flex:1;" @keyup="form.phone=form.phone.replace(/\D/g,'')" v-model="form.phone" placeholder="Phone number" />
                </div>
              </div>

              <div class="btn-box">
                <!-- <button class="deleteBtn" ></button> -->
                <span style="flex:1;"></span>
                <button class="save-btn" @click="saveAddress">Save</button>
              </div>

            </template>
            <!-- 非英国地区 -->
            <template v-else>
              <h3 :class="['edit-form-title',{'empty-emptyTipsShaing-animate': showEmpty && !saveAddressForm.doorNumber }]" >
                House Number
              </h3>
              <div class="edit-form-line">
                <input type="text" name="" v-if="currentTab==4" v-model="saveAddressForm.doorNumber" placeholder="House Number">
              </div>
              <h3 :class="['edit-form-title',{'empty-emptyTipsShaing-animate': showEmpty && !addressSearchForm.locationName }]" >
                Street / Road Building Name
              </h3>
              <div class="edit-form-line">
                <input type="text" name="" v-if="currentTab==4" v-model="addressSearchForm.locationName" placeholder="Street / Road">
              </div>
              <h3 :class="['edit-form-title',{'empty-emptyTipsShaing-animate': showEmpty && !addressSearchForm.formattedAddress}]" >
                Address
              </h3>
              <div class="edit-form-line">
                <input type="text" name="" v-if="currentTab==4" v-model="addressSearchForm.formattedAddress" placeholder="Apartment, Road ">
              </div>
  
              <div class="normal-form-line" style="height: 65px;margin: 0px auto 20px;" >
                
                <div style="flex:1;min-width: 42%;">
                  <h3 :class="['edit-form-title',{'empty-emptyTipsShaing-animate': showEmpty  && !addressSearchForm.country }]" >Preferred Name</h3>
                  <div class="edit-form-line">
                    <input type="text" name="" v-if="currentTab==4" v-model="form.nickName" placeholder="Name">
                  </div>
                </div>
                <span style="flex:1;" ></span>
                <div style="flex:1;min-width: 58%;">
                  <h3 :class="['edit-form-title',{'empty-emptyTipsShaing-animate': showEmpty && !form.email }]" style="width: 100%;">Email</h3>
                  <div class="edit-form-line" style="width: 100%;">
                    <input style="font-size:14px" type="text" name="" v-if="currentTab==4" v-model="form.email" placeholder="Email">
                  </div>
                </div>
              </div>

              <h3 :class="['edit-form-title',{'empty-emptyTipsShaing-animate': showEmpty  && !form.phone }]" >Phone Number</h3>
              <div class="normal-form-line">
                <div class="area-box">
                  <PhoneBtn v-model="form.code"></PhoneBtn>
                </div>
                <div class="shadow-box" >
                  <input type="text" name="" v-if="currentTab==4" style="flex:1;" @keyup="form.phone=form.phone.replace(/\D/g,'')" v-model="form.phone" placeholder="Phone number" />
                </div>
              </div>

              <h3 :class="['edit-form-title']" >
                Note For Driver
              </h3>
              <div class="edit-form-line">
                <input type="text" name=""  v-model="addressSearchForm.deliveryInstrustion" placeholder="building name is cecil house">
              </div>

              <div class="btn-box">
                <!-- <button class="deleteBtn" ></button> -->
                <span style="flex:1;"></span>
                <button class="save-btn" @click="saveAddress">Save</button>
              </div>

            </template>



          </div>

        </div>

        <!-- 蒙层 -->
        <div class="loading-box" v-if="blockLoading">
          <el-skeleton :loading="blockLoading" animated>
            <template slot="template">
              <el-skeleton-item variant="rect" :style="{height: (currentTab<4?'500px': '730px')}" />
            </template>
            <div style="height: 400px"></div>
          </el-skeleton>
        </div>

        <!-- loading 蒙层 -->
        <div class="fetch-load-box" v-if="fetchLoading">
          <i class="el-icon-loading"></i>
        </div>
      </div>
    </div>

    <!-- 验证码滑块模块 -->
    <Verify
      @success="sendcodeMsg"
      :mode="'pop'"
      :captchaType="'blockPuzzle'"
      :imgSize="{ width: '330px', height: '155px' }"
      ref="verify"
    ></Verify>
  </div>
</template>

<script>
import { postGateway } from "@/request";
import PhoneBtn from "@/components/PhoneBtn.vue";
import Verify from "@/components/Verifition/Verify";
import debounce from '@/utils/debounce';
import bus from "@/utils/bus.js";
export default {
  metaInfo: {},
  name: "Login",
  components: {
    PhoneBtn,
    Verify,
  },
  props: {
    transType: String,
    addAddress: Boolean,
    merchantId: [String, Number]
  },
  data() {
    return {
      form: {
        phone: "",
        code: (this.$isUk?'+44':'+353'),
        verCode: "", // 手机验证码
        nickName: '',
        email: ''
      },
      inputFocus: false,
      currentTab: 4,
      agree: false,
      canGetCode: true,
      sendPhonecode: "Send Verification code",
      num: 0,
      blockLoading: true,
      keywords: '', // 搜索地址关键词
      focusNow: false,
      resultList: [],
      loading: false,
      itemLoading: null,
      addressSearchForm: {
        city: '',
        country: '',
        state: '',
        street: '',
        zipcode: '',
        formattedAddress: '',
        locationName: '',
        lat: '',
        lng: '',
        deliveryInstrustion: ''
      },
      userInfo: {

      },
      saveAddressForm: {
        fullName: '',
				clientId: null,
				contactPhone: '',
				dontDoorNum: false, // 是否 没有门牌号
				street: '',
				city: '',
				state: '', // 目前不知道是啥
				country: '',
				countryCode: '',
				ipAddress: "", // ip地址
				latitude: "", // 经度
				longitude: "", // 纬度
				doorNumber: '', // 门牌号
				locationName: "", //楼层信息
				asDefault: true, // 是否设为默认
				formattedAddress: '',  //格式化之后的地址
				zipcode: '',  //邮编
      },
      showAgreeAnimation: false,
      showEmpty: false,
      fetchLoading: false,
      secondSend: 0,
      countryList: [],
      useGoogleSearch: false,
    };
  },
  computed: {
    cantLogin() {
      return this.form.verCode.length === 4;
    },
    isMobile() {
      return window.innerWidth < 950
    }
  },
  watch: {
    'addAddress': function(val) {
      if(val) {
        // this.$refs.carousel.setActiveItem(3);
        this.currentTab = 3;
      }
    },
    'showEmpty': function(val) {
      this.scrollToTarget();
      if(val) {
        setTimeout(() => {
          this.showEmpty = false;
        }, 4000);
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      if(this.addAddress) {  // 用于添加地址的时候
        this.currentTab = 3;
      } else {
        this.currentTab = 0;
      }
      setTimeout(() => {
        this.blockLoading = false;
      }, 300);
    });
    if(localStorage.getItem('token')) {
      this.getUserInfo();
      this.getCountryList();
    }
    this.useGoogleSearch = !this.$isUk;
  },
  methods: {
    gotCode() {
      if (!this.form.phone.trim()) {
        this.$message({
          message: "Please enter phone number",
          type: "warning",
        });
        return;
      }
      if (this.form.phone.indexOf(44) === 0) {
        this.$message({
          message: "Without the '44' country code.",
          type: "warning",
        });
        return;
      }
      this.currentTab = 1;
    },
    cantFindAd() {
      let obj = {
        formattedAddress: '',
        locationName: '',
        zipcode: '',
        city: '',
        latitude: '',
        longitude: '',
        lat: '',
        lng: '',
        street: '',
        country: this.$isUk?'United Kingdom':'Ireland',
        countryCode: this.$isUk?'GB':'IRE',
        deliveryInstrustion: ''
      }
      this.addressSearchForm = obj;
      this.nextTab(4)
    },
    changTab(index) {
      console.log(index)
      this.currentTab = index;
    },
    goBack() {
      if(this.addAddress) {
        this.$emit('addressDone', false);
        return
      }
      this.$router.go(-1);
    },
    gotoPrivate() {
      this.$router.push("/Privacy-Policy");
    },
    preTab() {  // 默认tab设置
      if(this.currentTab > 0) {
        this.currentTab--;
      }
    },
    nextTab(val) {
      if(val) {
        this.currentTab = val;
      } else {
        this.currentTab++;
      }
      console.log(this.currentTab,' currentTab')
    },
    // 显示验证滑块 暂时下线
    async showCaptcha() {
      // this.sendcodeMsg({captchaVerification:''});
      // return
      /* eslint-disable */
      if (!this.num == 0) return;
      if (!this.form.phone.trim()) {
        this.$message({
          message: "Please enter phone number",
          type: "warning",
        });
        return;
      }
      if (this.form.phone.indexOf(44) === 0) {
        this.$message({
          message: "Without the '44' country code.",
          type: "warning",
        });
        return;
      }

      // 加一个校验手机号开头
      let phoneCode = this.form.code.replace('+','');
      if(this.form.phone.indexOf(phoneCode) === 0) {
        this.$message({
          message: "Please check phone number width '" + phoneCode + "'",
          type: "warning",
        });
        return;
      }
      
      if (!this.agree) {
        this.$message({
          message: "Please agree the political",
          type: "warning",
        });
        this.showAgreeAnimation = true;
        setTimeout(() => {
          this.showAgreeAnimation = false;
        }, 1000);
        return;
      }
      if (!this.canGetCode) return;
      this.$refs.verify.show();
    },
    //发送手机验证码
    async sendcodeMsg({ captchaVerification }) {
      this.fetchLoading = true;
      let url = `/customer/atClient/smsSend`
      if (this.secondSend > 1) {
          url = `/customer/atClient/smsSendTextlocal`
      }
      postGateway({
        url: url,
        data: {
          phone: this.form.code + this.form.phone.trim(),
          captchaType: "register",
          captchaVerification,
        },
      }).then((res) => {
        if (res.code == 200) {
          this.$message({
            message: "Verification sent successfully",
            type: "success",
          });
          this.fetchLoading = false;
          if(this.currentTab < 1) {  // 如果实在第一个tab 发完短信去下一个tab
            this.nextTab(1);
          }
          this.secondSend++
        }
      }).catch(()=>{
        this.$message({
          message: 'You have reached login attempt limit. Please try again in a few hours',
          type: 'error'
        })
        this.fetchLoading = false;
      })
      this.num = 60;
      this.timer2 = setInterval(() => {
        this.num--;
        this.canGetCode = false;
        this.sendPhonecode = 'After ' + this.num + " seconds retry";
        if (this.num == 0) {
          this.sendPhonecode = "Resend code";
          this.canGetCode = true;
          clearInterval(this.timer2);
        }
      }, 1000);
    },
    login() {  // 登录

      if(!this.form.verCode.length) {
        return
      }
      let { code, phone } = this.form;
      this.fetchLoading = true;
      postGateway({
        url: "/sys/client/clientLogin",
        method: "POST",
        data: {
          phone: code + phone,
          code: this.form.verCode,
          type: "web",
        },
      }).then((res) => {
        if (res.data.token) {
          let { token, refreshToken, clientId } = res.data;
          localStorage.setItem("token", token);
          localStorage.setItem("refreshToken", refreshToken);
          localStorage.setItem("clientId", clientId);
          localStorage.setItem("aeClient", clientId);
          bus.$emit("login"); // 通知header登陆了
          
          this.getUserInfo().then(res=>{
            this.fetchLoading = false;
            console.log(res,'用户登录回调')
            /* eslint-disable */ 
            if(this.userInfo.emailAddress) {  //如果有邮箱 说明老用户 手机用户跳过补充信息页面
              this.$emit('loginDone', true)
              this.getUserInfo()
            } else {
              this.$emit('needFill', true)
              this.nextTab(2);
            }
          })
          this.$nextTick(()=>{
            this.getCountryList();
          })
        }
        console.log(res, "登录回调");
      }).catch((err)=>{
        this.$message.error(err.msg)
        this.fetchLoading = false;
      })

    },
    getUserInfo() {
      return postGateway({
        url: '/customerApp/center/getDetail',
        method: 'GET',
        data: {
          clientId: localStorage.getItem('clientId')
        }
      }).then(res=>{
        this.userInfo = res.data;
        this.form.nickName = res.data.firstName || res.data.nickName;
        this.form.email = res.data.emailAddress;
        // 补全手机号
        if(res.data.contactPhone.indexOf('+44') == 0) {
					this.form.phone = res.data.contactPhone.replace('+44','')
					this.form.code = '+44';
				}
        if(res.data.contactPhone.indexOf('+353') == 0) {
					this.form.phone = res.data.contactPhone.replace('+353','')
					this.form.code = '+353';
				}
				if(res.data.contactPhone.indexOf('+86') == 0) {
					this.form.phone = res.data.contactPhone.replace('+86','');
					this.form.code = '+86';
				}
        if(res.data.contactPhone.indexOf('44') == 0) {
					this.form.phone = res.data.contactPhone.replace('44','')
					this.form.code = '+44';
				}
        if(res.data.contactPhone.indexOf('353') == 0) {
					this.form.phone = res.data.contactPhone.replace('353','')
					this.form.code = '+353';
				}
				if(res.data.contactPhone.indexOf('86') == 0) {
					this.form.phone = res.data.contactPhone.replace('86','');
					this.form.code = '+86';
				}
        console.log(res.data, '获取用户信息')
      })
      .catch()
    },
    getCountryList() {
      postGateway({
        url: '/districtCode/getDistrictSelect',
        method: 'GET',
        data: {
          level: 1,
        }
      }).then(res=>{
        this.countryList = res.data
      })
      .catch()
    },
    submitNameInfo() {  // 补充姓名 邮箱
      // 补充用户信息 - 登录之后
      let { code, phone, nickName, email } = this.form;
      
      this.fetchLoading = true;
      let nameArr = nickName.split(' ');
      postGateway({
        url: '/customer/atClient/savePersonalInfo',
        method: 'POST',
        data: {
					clientId: localStorage.getItem('clientId'),
					avatar: '',
          nickName: nickName,
          firstName: nameArr[0],
          lastName: nameArr.length>1?nameArr[1]:'',
          emailAddress: email,
          contactPhone: code + phone,
				}
      }).then(res=>{
        console.log(res,'用户信息更新成功')
        if(this.transType != 'delivery' || this.isMobile) {
          this.fetchLoading = false;
          this.$emit('loginDone',true)
          this.$emit('needFill', false)
          return
        }
        this.$emit('loginDone',true)
        this.$emit('needFill', false)
        // this.nextTab(3)
        this.fetchLoading = false;
      }).catch(()=>{
        this.fetchLoading = false;
      })
    },
    // 搜索地址相关
    inputNow() {
      this.resultList = [];
      this.loading = true;
      this.focusNow = true;
      debounce(() => {
        this.googlePreSearch();
      }, 500);
    },
    googlePreSearch() {  // 自动补全地址
      if(this.$isUk && !this.useGoogleSearch) {
				postGateway({
					url: `/customerApp/addressioSearch/autocomplete`,
					method: 'GET',
					data: {
						keyword: this.keywords
					}
				}).then(res=>{
					console.log(res.data,'搜索地址会餐')
					this.loading = false;
					this.resultList = res.data.suggestions.map(x=>({
            ...x,
            content: x.description || x.address,
            place_id: x.id,
            type: "location",
          }));
				})
				.catch(err=>{
					this.loading = false;
					console.log(err,'搜索地址报错')
				})
				return
			}
      postGateway({
        method: "GET",
        url: "/customerWeb/googleSearch/autocomplete",
        data: { keyword: this.keywords },
      })
      .then((res) => {
        this.resultList = this.resultList.concat(
          res.data.predictions.map((x) => {
            return {
              ...x,
              content: x.description,
              type: "location",
            };
          })
        );
        this.loading = false;
      })
      .catch(() => {
        this.loading = false;
      });
    },
    // 根据结果跳转下一步
    normalSearch(item) {
      this.googleSearch(item);
    },
    googleSearch(item) {
      this.itemLoading = item.place_id;

      if(this.$isUk && !this.useGoogleSearch) {
				postGateway({
					url: `/customerApp/addressioSearch/get`,
					method: 'GET',
					data: {
						id: item.id
					}
				}).then(res=>{
					this.itemLoading = '';
          if (res.code == 200) {
            let { formatted_address, postcode, longitude, latitude,town_or_city, country, line_1, deliveryInstrustion  } = res.data;
            let obj = {
              formattedAddress: formatted_address.join(','),
              zipcode: postcode,
              city: town_or_city,
              latitude: latitude,
              longitude: longitude,
              lat: latitude,
              lng: longitude,
              street: line_1,
              country: country,
              locationName: '',
              countryCode: 'GB',
              deliveryInstrustion: deliveryInstrustion?deliveryInstrustion:''
            }
            this.addressSearchForm = obj;
            console.log(this.addressSearchForm, '用户选择地址')
            this.nextTab(4)
          } else {
            this.$message.error("Error happen...");
          }
					
				})
				.catch(err=>{
					console.log(err,'搜索地址报错')
				})
				return
			}

      postGateway({
        method: "GET",
        url: "/customerApp/googleSearch/placeGeoCode",
        data: {
          keyword: "",
          placeId: item.place_id,
        },
      }).then((res) => {
        this.itemLoading = '';
        if (res.code == 200) {
          this.addressSearchForm = {
            ...res.data,
            locationName: '',
            deliveryInstrustion: ''
          };
          console.log(this.addressSearchForm, '用户选择地址')
          this.nextTab(4)
        } else {
          this.$message.error("Error happen...");
        }
      }).catch(err=>{
        this.itemLoading = '';
        console.log(err,'err')
      })
    },
    scrollToTarget() {
      let dom = document.querySelector('.payment-box');
      let target = dom.offsetTop-120;
      if(window.innerWidth < 950) {
        target = dom.offsetTop - 100;
      }
      document.body.scrollTop = document.documentElement.scrollTop  = target;
    },
    // 保存用户地址表格
    saveAddress() {
      
      let { code, phone, nickName } = this.form;
      let { city, country, state, street, zipcode ,formattedAddress, locationName, lat, lng, deliveryInstrustion } = this.addressSearchForm;
      let countryCode = ""
      if(country == 'England' || country == 'Scotland') {
        countryCode = 'GB'
      }
      // let countryName = this.countryList.filter(x=>x.code===country)[0].text
      if((!this.saveAddressForm.doorNumber && !this.saveAddressForm.dontDoorNum)) {
				this.$message.info('Please fill the Door Number')
        this.showEmpty = true;
				return
			}

      if(!this.addressSearchForm.locationName && !this.$isUk) {
        this.showEmpty = true;
				return
			}


      let param = {
        city, state, street, zipcode ,
        formattedAddress,
        locationName,
        latitude: lat,
        longitude: lng,
        lat, 
        lng,
        contactPhone: code + phone,
        fullName: nickName,
        clientId: localStorage.getItem('clientId'),
        country: country,
        countryCode: countryCode || 'GB',
        isDoorNumber: this.saveAddressForm.dontDoorNum ? 0 : 1,
        asDefault: this.saveAddressForm.asDefault ? 1 : 0,
        doorNumber: this.saveAddressForm.doorNumber,
        deliveryInstrustion
      }

      // 英国地区的地址同步一下 locationName
      if(this.$isUk && !param.locationName && param.street) {
        param.locationName = param.street
      }

      if(!param.fullName) {
				this.$message.info('Please fill the Full Name')
        this.showEmpty = true;
				return
			}
			
			if(!phone) {
				this.$message.info('Please fill the Phone Number')
        this.showEmpty = true;
				return
			}

      let phoneCode = this.form.code.replace('+','');
      if(this.form.phone.indexOf(phoneCode) === 0) {
        this.$message({
          message: "Please check phone number width '" + phoneCode + "'",
          type: "warning",
        });
        return;
      }
			
			// if(!param.street) {
			// 	this.$message.info('Please fill the Addres')
      //   this.showEmpty = true;
			// 	return
			// }
			
			if(!param.city && this.$isUk) {
				this.$message.info('Please fill the City')
        this.showEmpty = true;
				return
			}
			if(!param.countryCode && this.$isUk) {
				this.$message.info('Please fill the Country')
        this.showEmpty = true;
				return
			}
			if(!param.zipcode && this.$isUk) {
				this.$message.info('Please fill the Postcode')
        this.showEmpty = true;
				return
			}
      console.log(param, '添加地址传参')
      this.fetchLoading = true;
      postGateway({
        url: '/customer/address/add',
        method: 'POST',
        data: param
      }).then(res=>{
        this.fetchLoading = false;
        this.$emit('loginDone',true)
        console.log(res,'添加地址传参')
        /* eslint-disable */
        if(!this.userInfo.emailAddress  || !this.userInfo.firstName) {
          this.submitNameInfo()
        }
      })
      .catch(()=>{
        this.fetchLoading = false;
      })

      
      

    }
  },
};
</script>

<style lang="scss" scoped>
$mainColor: #aa0bc0;
$greenColor: #26d07c;
$yellowColor: #ffa132;
.form-box {
  // width: 780px;
  min-height: 580px;
  background: #fff;
  border-radius: 2px;
  padding: 0 40px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
  input {
    font-weight: 600;

    &::placeholder {
      font-weight: 400;
    }
  }

  h2 {
    font-weight: 800;
    color: #000;
    font-size: 35px;
    text-align: center;
    margin-top: 0;
    margin-bottom: 8px;
  }
  p {
    font-size: 18px;
    text-align: center;
    margin: 0;
    .under-line {
      text-decoration: underline;
      margin: 0;
      cursor: pointer;
      color: #2885e2;
    }
  }

  .input-box {
    margin-top: 28px;
    display: flex;
    align-items: center;
    justify-content: center;

    .area-box {
      height: 56px;
      width: 114px;
      margin-right: 10px;
      border-radius: 4px;
      box-shadow: 0px 2px 5px #8f9698;
    }
    .input {
      height: 56px;
      flex: 1;
      box-shadow: 0px 2px 5px #8f9698;
      border-radius: 4px;
      padding-left: 20px;
      font-size: 20px;
      font-weight: 600;
      &::placeholder {
        font-weight: 500;
      }
    }
  }

  .check-box {
    margin-top: 20px;
    display: flex;
    font-size: 18px;
    padding-left: 126px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    :deep(.el-checkbox__inner) {
      background-color: #dcdfe6;
    }
    :deep(
        .el-checkbox__input.is-checked .el-checkbox__inner,
        .el-checkbox__input.is-indeterminate .el-checkbox__inner
      ) {
      background-color: #09ca6a;
      border-color: #09ca6a;
    }
    :deep(.el-checkbox__inner) {
      transform: scale(1.4);
    }
    span {
      margin-left: 15px;
      cursor: pointer;
    }
    p {
      margin-top: 10px;
      display: block;
      font-size: 14px;
      line-height: 20px;
      color: #474747;
      text-align: left;
      .under-line {
        text-decoration: underline;
        margin: 0;
        cursor: pointer;
        color: #2885e2;
      }
    }
  }
  @keyframes shakingAnimation {
		0% {
			transform: translateX(-3%); 
			color: red;
		}
		10% {
			transform: translateX(63); 
			color: red;
		}
		20% {
			transform: translateX(-2%); 
			color: red;
		}
		30% {
			transform: translateX(2%); 
			color: red;
		}
		40% {
			transform: translateX(-1%); 
			color: red;
		}
		50% {
			transform: translateX(1%); 
		}
	}
  .shaking {
		color: rgb(199, 12, 12);
		transform: translateX(0); 
		animation: shakingAnimation 1s ease-in-out forwards;
	}
  .sign-btn {
    margin-left: 128px;
    margin-top: 20px;
    height: 58px;
    background-color: #09ca6a;
    color: #fff;
    font-size: 20px;
    letter-spacing: 1px;
    font-weight: 600;
    border-radius: 6px;
    box-shadow: 0px 3px 6px #8f9698;
    transition: all 0.2s ease-in;
    cursor: pointer;
    &:hover {
      background-color: #0fda74;
      box-shadow: 0px 5px 10px #6f7577;
    }
  }
  .being-send {
    height: 28px;
    font-size: 15px;
    margin-top: 10px;
    text-decoration: underline;
    margin-left: 128px;
    cursor: pointer;
    &:active {
      color: #6f7577;
    }
  }
  .disable-btn {
    background-color: #999;
    &:hover {
      background-color: #999;
      box-shadow: 0px 5px 10px #6f7577;
    }
  }
  .ver-code {
    margin: 40px auto 20px;
    display: flex;
    position: relative;
    width: 400px;
    overflow: visible;
    box-shadow: 0px 3px 6px #8F9698;
    input {
      height: 58px;
      z-index: 10;
      font-size: 30px;
      width: 400px;
      background-color: transparent;
      box-sizing: border-box;
      border-radius: 4px;
      letter-spacing: 40px;
      text-align: center;
      font-weight: 600;

      &::placeholder {
        letter-spacing: normal;
        font-weight: 400;
        color: #c6c8cb;
        font-size: 25px;
      }
    }
    .mask-box {
      display: flex;
      display: none;
      justify-content: center;
      width: 400px;
      .font-box {
        height: 65px;
        min-width: 68px;
        border-radius: 4px;
        margin-right: 30px;
        box-shadow: 0px 3px 6px #8f9698;
        background-color: #fff;
        font-size: 30px;
        color: #000;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        position: relative;

        .ol-line {
          height: 40px;
          width: 2px;
          border-radius: 1px;
          background-color: #000;
          animation: breath 1.2s ease-in-out infinite alternate forwards;
          position: absolute;
          left: 22%;
          top: 50%;
          transform: translateY(-50%);
          z-index: 20;
        }
      }
    }
  }

  .resend-btn {
    height: 32px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    margin: 0 auto;
    color: $mainColor;
    &.disabled {
      color: #999;
    }
  }

  .name-tips {
    margin: 24px 0 40px;
  }

  .form-line {
    margin: 0 auto 20px;
    display: flex;
    width: 60%;
    box-shadow: 0px 2px 12px rgba(16, 16, 16, 0.15);
    height: 48px;
    font-size: 18px;
    color: #000;
    border-radius: 4px;
    overflow: hidden;

    input {
      height: 100%;
      width: 100%;
      padding-left: 20px;
      color: #000;
      font-size: 20px;
    }
  }

  .swtich-box {
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;
    margin-right: 30px;
    span {
      font-size: 14px;
      font-weight: bold;
      margin-right: 12px;
      display: inline-flex;
      align-items: center;
      cursor: default;

      i {
        margin: 0 4px;
      }
    }
  }

  .form-address-line {
    margin: 10px auto 20px;
    display: flex;
    height: 48px;
    font-size: 18px;
    width: 90%;
    position: relative;

    .input-address-box {
      flex: 1;
      display: inline-flex;
      align-items: center;
      height: 100%;
      box-shadow: 0px 2px 12px rgba(16, 16, 16, 0.15);
      border-radius: 4px;
      margin-top: 0;
      padding-left: 20px;
      .el-icon-location {
        color: #000;
        margin-right: 12px;
        font-size: 20px;
      }
      input {
        flex: 1;
        height: 100%;
        font-size: 18px;
        font-weight: 600;
        color: #000;
      }
    }
    
    .search-btn {
      background: #09CA6A;
      box-shadow: 0px 2px 12px rgba(16, 16, 16, 0.15);
      color: #fff;
      display: flex;
      align-items: center;
      margin-left: 10px;
      height: 100%;
      padding: 0 20px;
      white-space: nowrap;
      font-size: 15px;
      font-weight: 600;
      border-radius: 4px;
      cursor: pointer;

      .el-icon-search {
        height: 20px;
        width: 20px;
        margin-right: 8px;
      }
    }

    .expand-box {
      position: absolute;
      width: 90%;
      height: 0px;
      top: 50px;
      left: 12px;
      background: rgba(255, 255, 255, 0.95);
      padding: 20px 0;
      height: 0px;
      opacity: 0;
      transition: all 0.8s ease-in-out 0s;
      box-shadow: 0px 2px 12px rgba(16, 16, 16, 0.15);

      .search-item {
        height: 50px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #e9e9e9;
        padding: 0 20px;
        cursor: pointer;

        .el-icon-loading {
          font-size: 18px;
          color: #aa0bc0;
          font-weight: bold;
        }

        &:hover {
          background-color: rgba(236, 230, 230, 0.95);
        }

        span {
          flex: 1;
        }

        img {
          height: 18px;
          width: 25px;
        }
      }
    }

    .load-box {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .loader {
        width: 48px;
        height: 48px;
        border: 5px solid #fff;
        border-bottom-color: #ff3d00;
        border-radius: 50%;
        display: inline-block;
        box-sizing: border-box;
        animation: rotation 1s linear infinite;
      }

      @keyframes rotation {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }

    .empty-btn {
      margin: 10px auto;
      height: 60px;
      background-color: #02c757;
      border-radius: 4px;
      width: 300px;
      font-size: 18px;
      color: #fff;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    .expand-box-active {
      height: 280px;
      opacity: 1;
      overflow-y: scroll;
    }

  }

  .edit-form-line {
    margin: 0 auto;
    width: 95%;
    height: 40px;
    display: flex;
    border-radius: 4px;
    overflow: hidden;
    align-items: center;
    box-shadow: 0px 2px 12px rgba(16, 16, 16, 0.15);

    input {
      height: 100%;
      width: 100%;
      padding-left: 20px;
      font-size: 16px;
    }
  }

  .normal-form-line {
    display: flex;
    align-items: center;
    width: 95%;
    height: 40px;
    font-weight: 600;
    margin: 12px auto 0;

    .area-box {
      box-shadow: 0px 2px 12px rgba(16, 16, 16, 0.15);
      margin-right: 20px;
      border-radius: 6px;
    }

    .check-tips {
      margin-left: 10px;
      white-space: nowrap;
      font-size: 15px;
    }

    input {
      height: 100%;
      padding-left: 20px;
      font-size: 16px;
    }
    .shadow-box {
      flex: 1;
      box-shadow: 0px 4px 4px rgba($color: #000000, $alpha: 0.2);
      height: 100%;
      input {
        width: 100%;
        flex: unset;
      }
    }

    :deep(.el-checkbox__inner) {
      background-color: #dcdfe6;
    }
    :deep(
        .el-checkbox__input.is-checked .el-checkbox__inner,
        .el-checkbox__input.is-indeterminate .el-checkbox__inner
      ) {
      background-color: #09ca6a;
      border-color: #09ca6a;
    }
    :deep(.el-checkbox__inner) {
      transform: scale(1.4);
    }
  }

  .edit-form-title {
    width: 95%;
    font-weight: 600;
    margin: 16px auto 8px;
  }

  .btn-box {
    width: 98%;
    display: flex;
    margin-top: 40px;
    padding-bottom: 5px;
    .save-btn {
      height: 48px;
      padding: 0 40px;
      border-radius: 24px;
      background: #09CA6A;
      color: #fff;
      font-weight: bold;
      font-size: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0px 2px 12px rgba(16, 16, 16, 0.15);
      cursor: pointer;
      transition: all .2s ease-in-out;

      &:hover {
        background: #08b35e;
      }
    }
  }

  @keyframes breath {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .top-box {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 20px;

    i {
      font-size: 26px;

      color: #000;
      cursor: pointer;
      transition: all ease-in 0.2s;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
        0px 4px 4px rgba(0, 0, 0, 0.25);
      &:hover {
        color: $mainColor;
      }
    }
    .back {
      position: relative;
      left: -30px;
      cursor: pointer;
    }
    .close {
      position: relative;
      right: -40px;
    }
  }

  .slide-box {
    position: relative;
    .loading-box {
      position: absolute;
      top: 0;
      left: 0;
      height: 500px;
      width: 100%;
      z-index: 50;
    }
    .fetch-load-box {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 51;
      background: rgba(255, 255, 255, 0.8);

      .el-icon-loading {
        font-size: 35px;
      }
    }
  }

  :deep(.el-select .el-input .el-input__inner) {
    font-weight: 600!important;
    color: #000;
  }
}

@media screen and (min-width: 1300px) and (max-width: 1550px) {
  // 默认宽度盒子
  .input-box {
    zoom: 0.8
  }
  .check-box {
    padding-left: 0!important;
  }
  .sign-btn, .being-send {
    margin-left: 0!important;
  }
  .section-login { 
    .form-box .slide-box{
      .ver-code {
        width: 380px;
      }
    }
  }
}

@media screen and (max-width: 950px) {
  .section-login {
    margin-right: 0px!important;
    .form-box {
      padding: 0 30px;
      .slide-box {
        h2 {
          font-size: 20px;
        }
        p {
          font-size: 14px;
        }
        .input-box {
          margin-top: 20px;
          .area-box {
            height: 40px;
            width: 82px;
          }
          .input  {
            z-index: 10;
            height: 40px;
            font-size: 14px;
            min-width: 140px;
          }
          .shadow-box {
            flex: 1;
            box-shadow: 0px 4px 4px rgba($color: #000000, $alpha: 0.2);
            height: 100%;
            input {
              width: 100%;
              flex: unset;
              padding-left: 0;
              text-align: center;
            }
          }
        }
        .check-box {
          padding-left: 0;
        }

        .ver-code {
          width: 94%;
          // padding-left: 25px;
          input {
            width: 100%;
          }
          .mask-box {
            width: 100%;
            // padding-left: 15px;
            .font-box {
              height: 45px;
              min-width: 48px;
              font-size: 25px;
            }
          }
        }
        .sign-btn {
          width: 96%!important;
        }
        .confirm-btn {
          width: unset!important;
          zoom: 0.8;
        }
        .name-tips {
          margin: 12px 0 20px;
        }

        .swtich-box {
          margin-right: 0px;
        }
        .form-address-line {
          width: 100%;
          flex-direction: column;
          height: auto;
          .input-address-box {
            height: 48px;
            flex: unset;
            input {
              flex: unset;
              width: 100%;
            }
          }
          .expand-box {
            width: 100%;
            padding: 0;
            left: 0%;
            .search-item {
              height: unset;
              padding: 10px 5px 10px 20px;
              img {
                height: unset;
                width: 13px;
                margin-left: 10px;
              }
            }
          }
          .search-btn {
            margin-top: 25px;
            height: 48px;
            margin-left: 0px;
            justify-content: center;
            .text {
              flex: 1;
              text-align: center;
              padding-right: 20px;
              text-align: center;
              font-size: 20px;
            }
          }
        }

      }
      .sign-btn {
        margin-left: 0;
      }
      .being-send {
        margin-left: 0;
      }
      .form-line {
        width: 90%;
        height: 40px;
      }
      .normal-form-line {
        flex-wrap: wrap;
        display: flex;
        .check-tips {
          font-size: 16px;
        }
      }
    }
  }
  
}
</style>
