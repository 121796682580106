<template>
  <div class="merchant-item">
    <div
      class="img-box"
      :style="{ 'background-image': `url(${item.webmerchantLogo?item.webmerchantLogo:item.restaurantImg})` }"
      @click="goMerchant(item)"
    ></div>
    <div class="info-box" @click="goMerchant(item)">
      <div class="title">{{ item.restaurantName }}</div>
      <div class="cuisines">{{ item.cuisines }}</div>
      <div class="rate">
        <el-rate
          style="position: relative; top: 2px"
          v-model="tasteRating"
          :disabled="true"
          :colors="['#aa0bc0','#aa0bc0','#aa0bc0']"
          disabled-void-color="#CFC9C3"
        ></el-rate>
        <template v-if="item.reviewCount">
          (<span> {{ item.reviewCount }} </span>)
        </template>
      </div>
      <div class="discount-tag" v-if="item.merchantOff*1">{{ item.merchantOff }} %</div>
      <div class="n-item">
        🕒
        <span class="spend-time" style="margin-left: 7px;">
          ETA: {{
          item.merchantDeliveryEstimation
            ? item.merchantDeliveryEstimation
            : "-"
        }}
        </span>
        <span style="flex: 1;" ></span>
        <span class="free-delivery-tag" v-if="item.freeDeliveryAbovePrice" >
          🛵 &nbsp; Free Delivery Available
        </span>
      </div>
      <div class="n-item">
        <i class="el-icon-location"></i>
        <span class="distance">{{ item.distince }} miles away</span>
      </div>
      <div class="n-item" v-if="item.lowestPriceGuarantee" >
        <img class="icon-img" v-if="$isUk" src="@/assets/menu/icon_bounds.png" alt="">
        <img class="icon-img" v-else src="@/assets/menu/icon_eur.png" alt="">
        <span class="distance" style="font-weight: bold;color: #b90ed0;" >Lowest Price Guarantee</span>
      </div>
      <div :class="['status-tag', 'status-busy' ]" v-if="item.holidayType == '3' && item.merchantCloseStore == 0" >
        Too Busy
      </div>
      <div :class="['status-tag', 'status-busy']" v-else-if="item.holidayType == '1' && item.merchantCloseStore == 1" >
        On Holiday
      </div>
      <div :class="['status-tag', { 'status-close': item.merchantCloseStore && !item.preOrder }]" v-else-if="!item.holidayType" >
        {{ item.merchantCloseStore ?( item.preOrder? "Pre-order" : "Closed") : "Open" }}
      </div>
    </div>
    <div class="card-footer">
      <span v-if="item.delivery">
        Delivery <i class="el-icon-success"></i>
      </span>
      <span v-if="item.pickup"> Pick-up <i class="el-icon-success"></i> </span>
      <span v-if="item.dinein"> Dine-in <i class="el-icon-success"></i> </span>
    </div>
  </div>
</template>

<script>
// eslint-disable
export default {
  props: {
    item: Object
  },
  data() {
    return {
      tasteRating: 5,
    };
  },
  watch: {
    item: function(val) {
      this.tasteRating = val.tasteRating
    }
  },
  mounted() {},
  methods: {
    goMerchant(item) {
      console.log(item, 'item')
      this.$router.push({
          path: '/menu-' + item.restaurantSlug
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.merchant-item {
  width: 300px;
  min-height: 358px;
  margin: 0 15px 15px 0;
  background-color: #fff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  display: inline-flex;
  overflow: hidden;
  flex-direction: column;
  cursor: pointer;
  transition: all ease-in-out 0.2s;
  font-family: "Source Sans Pro", sans-serif;

  &:hover {
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.65);
  }

  .img-box {
    background: #f3f3f1;
    height: 164px;
    width: 100%;
    overflow: hidden;
    background-position: center;
    background-size: cover;
    border-radius: 8px 8px 0 0;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }
  }
  .info-box {
    padding: 5px 5px 5px 10px;
    color: #000;
    flex: 1;
    position: relative;

    .status-tag {
      position: absolute;
      bottom: 4px;
      right: 4px;
      background: #428600;
      font-weight: 600;
      color: #fff;
      height: 20px;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      padding: 0 10px;
      min-width: 64px;
    }
    .status-close {
      background: #e9e9e9;
      color: #939393;
    }
    .status-busy {
      background-color: #ff8000;
      color: #fff;
    }

    .title {
      font-weight: 600;
      font-size: 20px;
      word-break: normal;
      white-space: pre-wrap;
      padding-bottom: 10px;
    }
    .cuisines {
      font-size: 11px;
      color: #333;
      margin: 2px 0 4px 0;
      word-break: normal;
    }
    .discount-tag {
      margin-bottom: 4px;
      display: inline-block;
      padding: 4px 15px;
      background: #d61409;
      font-weight: 600;
      color: #fff;
      // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 5px 5px 0px 0px;
    }
    .n-item {
      margin: 2px 0;
      display: flex;
      span {
        font-size: 12px;
      }
      .spend-time {
        font-size: 12px;
        font-weight: 600;
      }
      .icon-img {
        height: 15px;
        width: 15px;
        margin-right: 7px;
      }
      .free-delivery-tag {
        padding: 1px 6px;
        border-radius: 4px;
        background-color: #b90dd0;
        color: #fff;
        margin-left: 6px;
      }
      i {
        font-size: 15px;
        margin-right: 6px;
        font-weight: 600;
      }
      .el-icon-location {
        color: #26d07c;
      }
    }
    .rate {
      display: flex;
      align-items: center;
      position: relative;
      left: -2px;
      top: -2px;
      font-size: 14px;

      span {
        color: #ff8000;
        font-weight: 500;
        position: relative;
      }
    }
  }
  .card-footer {
    min-height: 32px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    border-top: 1px solid #0000006b;
    padding: 5px 10px;
    font-size: 16px;
    span {
      margin-right: 8px;
      margin-top: 3px;
      white-space: nowrap;
    }
    .el-icon-success {
      color: #26d07c;
      margin-left: -2px;
    }
  }
}

@media screen and (max-width: 950px) {
  .merchant-item {
    width: 85vw;
    height: 300px;
    .img-box {
      background: #f3f3f1;
      height: 144px;
      width: 100%;
      overflow: hidden;
      background-position: center;
      background-size: cover;
      border-radius: 8px 8px 0 0;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }
    }
    .card-footer {
      font-size: 12px;
    }
  }
}

</style>
